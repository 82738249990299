import { useRouter } from '@abyss/web/hooks/useRouter';
import React from 'react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const DeeplinkContainer = () => {
  const { navigate } = useRouter();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has('lineOfBusiness')) {
      navigate('/browse', {
        state: {
          lineOfBusiness: searchParams.get('lineOfBusiness'),
          policyNumber: searchParams.get('policyNumber'),
          reciprocityId: searchParams.get('reciprocityId'),
          planVariationCode: searchParams.get('planVariationCode'),
          coverageTypeCode: searchParams.get('coverageTypeCode'),
          coverageEffStartDate: searchParams.get('coverageEffStartDate'),
          coverageEffEndDate: searchParams.get('coverageEffEndDate'),
        },
      });
    }
  }, []);

  return <></>;
};
