import { config } from '@abyss/web/tools/config';

// In order to get it past LAWW's proxy, the remote calls paths need to follow
// a format. So we have a different path for LAWW.
export const getGraphqlUrl = (portalData, queryOptions) => {
  if (portalData?.portalConfig?.graphQlUrl) {
    return portalData.portalConfig.graphQlUrl;
  } else if (portalData?.portalName === 'LAWW') {
    return config('GRAPHQL_API_URL_LAWW');
  } else {
    return queryOptions.url;
  }
};
