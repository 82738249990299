import { config } from '@abyss/web/tools/config';
import get from 'lodash/get';
import { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import {
  CoverageTypesCodes,
  OPTUM_CORRELATION_ID_HEADER,
  ReverseCoverageTypesCodes,
} from '../../common/Constants';
import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../common/Utils';
import { getLanguage } from '../../frontends/ProviderSearch/context/Internationalization/helpers';
import { useCurrentMemberIsPreEffective } from '../../hooks/useCurrentMemberIsPreEffective';
import { CustomPreferredPolicy } from '../../models/Lagoon';
import { Address } from '../../models/ProviderDetails';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { conditionalValueRender } from '../../utils/common';
import {
  getAutoIncrementRadius,
  getLagoonConfigValue,
  getLob,
  getMemberHealthCoverageType,
  getSearchRadius,
} from '../../utils/providerSearch.utils';
import {
  getClaimOrEligibilitySystemTypeCode,
  getCoverageEffDateRange,
  getCoverageTypes,
  getCurrentMember,
  getIsCustomPreferredPolicy,
  getMemberPlanYear,
  getNetworkIdsForPES,
  getOnlineRetailers,
  getPlanVariationCode,
  getRulesPackageKey,
  getSitusState,
} from '../../utils/user.utils';
import { useCustomQuery } from '../useCustomQuery';
import { useGeoLocationStorage } from '../useGeoLocationStorage';
import { useLagoon } from '../useLagoon';
import {
  getExcludeNatAncForNetworkIds,
  getProviderIds,
} from '../useProviderSearch/useProviderSearch';
import GET_SNACK_CARD_PROVIDER_SEARCH_QUERY from './SnackCardProviderSearch.graphql';
import { useCategoryLLMFlag } from '../useCategoryLLMFlag';

export interface SnackCardProvider {
  providerId: string;
  providerName: string;
  providerType: string;
  primaryDegrees?: string[];
  specialities?: string[];
  distance: string;
  locationId: string;
  networkStatus?: string;
  npi: string;
  healthGradeRating?: string;
  organizationType?: string[];
  organizationCode?: string[];
  speciality: string;
  imageLocation: string;
  isTieredProvider?: boolean;
  isTier2Provider?: boolean;
  isOxPreferredLab?: boolean;
  isPreferredLab?: boolean;
  isFreeStandingFacility?: boolean;
  isGreenDiamondProvider?: boolean;
  ddpCode?: string;
  isPremiumCarePhysician?: string;
  website?: string;
  address?: Address;
  virtualCareOffered?: string[];
  firstName?: string;
  middleName?: string;
  lastName?: string;
  surveyCount?: number;
  networkEndDate?: string;
  networkStartDate?: string;
}

const filterSearchResults = (searchResult) => {
  const providerSearchResult = get(searchResult, 'snackCardProviderSearch', {});
  const filteredResult = providerSearchResult.snackCardList;
  return {
    isTieredPlan: providerSearchResult.isTieredPlan,
    rulesPackageKey: providerSearchResult.rulesPackageKey,
    totalCount: providerSearchResult.totalCount,
    snackCardList: filteredResult,
    searchRadius: providerSearchResult?.searchRadius,
  };
};

export const useSnackCardProviderSearch = (options) => {
  const [filtredResult, setFiltredResultResult] = useState({});
  const [headers, setHeaders] = useState({});
  const [queryResult, queryAction] = useCustomQuery(
    GET_SNACK_CARD_PROVIDER_SEARCH_QUERY,
    {
      ...options,
      url: config('GRAPHQL_API_URL'),
      accessor: 'snackCardProviderSearch',
      onCompleted: async (result) => {
        const data = filterSearchResults(result?.data);
        if (options?.onCompleted) {
          options.onCompleted(data);
        }
        setFiltredResultResult(data);
        setHeaders({
          correlationId: result.headers[OPTUM_CORRELATION_ID_HEADER],
        });
      },
      onError: (error) => {
        if (options?.onError) {
          options.onError(error);
        }
      },
    }
  );

  const parsedResult = {
    ...queryResult,
    data: filtredResult,
    headers,
  };

  return [parsedResult, queryAction];
};

export const useSnackCardProviderResults = (variables) => {
  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();

  const disablePCPIndAndANP = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DISABLE_PCP_IND_AND_ANP
  );

  const useMedicalNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_MEDICAL_NEW_ROLLUP_CODES
  );
  const useBHNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_BH_NEW_ROLLUP_CODES
  );
  const useVisionNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_VISION_NEW_ROLLUP_CODES
  );
  const useDentalNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_DENTAL_NEW_ROLLUP_CODES
  );
  const isFutureTerminationDemo: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.FUTURE_TERMINATION_DEMO
  );

  const uspTierOneFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_TIER1_ENABLE
  );

  const uspToggleFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_ENABLE
  );
  //TODO NEED to remove
  // const llmFlag = getFeatureFlag(
  //   featureFlags,
  //   ConstantsLagoon.FEATURE_FLAGS.ENABLE_LLM
  // );

  const categoryLlmFlag = useCategoryLLMFlag()
  const enableMockProviderIdsForPreEffective = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_MOCK_PROVIDER_IDS_FOR_PRE_EFFECTIVE
  );
  const enableTierOneMockProviderIds = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_TIER_ONE_MOCK_PROVIDER_IDS
  );

  const customPreferredPolicies: CustomPreferredPolicy[] = useLagoon(
    'custom-preferred-policies'
  )();
  const tierBenefits = useLagoon('tier-benefit')();
  const rollupCodeMapLagoonData = useLagoon('rollupcode-mapping')();

  const rollupCodeMap = rollupCodeMapLagoonData?.reduce((acc, item) => {
    if (item.source && item.destination) {
      acc[item.source] = item.destination;
    }
    return acc;
  }, {});

  const locale = getLanguage()?.code || 'en';
  const { storeCoverageType, storeLob } = useChipStore(
    useShallow((state: ChipState) => ({
      storeCoverageType: state.coverageType,
      storeLob: state.lob,
    }))
  );
  const {
    providerType,
    includeSpecialityRollupCodes,
    includeSpecRollupCodesArray,
    pcpIndicator,
    coverageType = storeCoverageType,
    onlineRetailers,
    shouldGetHGData = false,
    aoeCodes = [],
    behavioralProgramId,
    virtualCare = false,
    behavioralFacilityPrograms,
    search = '',
    isPCPLandingPage,
  } = variables;

  const { longitude, latitude, stateCode } = useGeoLocationStorage();
  const allConfigs = useLagoon('config');
  const searchRadius = getLagoonConfigValue(
    allConfigs,
    'DEFAULT_SEARCH_RADIUS'
  );
  const autoIncrementRadius = getLagoonConfigValue(
    allConfigs,
    'AUTO_INCREMENT_SEARCH_RADIUS'
  );
  const terminationProviderIdsDemo = getLagoonConfigValue(
    allConfigs,
    'TERMINATION_PROVIDER_IDS_DEMO'
  );
  const futureStartDateProviderIds = getLagoonConfigValue(
    allConfigs,
    'FUTURE_START_PROVIDERIDS'
  );
  const tierOneMockProviderIds = getLagoonConfigValue(
    allConfigs,
    'TIER1_MOCK_PROVIDERIDS'
  );
  const acoMockProviderIds = getLagoonConfigValue(
    allConfigs,
    'ACO_MOCK_PROVIDERIDS'
  );
  const premiumCareMockProviderIds = getLagoonConfigValue(
    allConfigs,
    'PREMIUM_MOCK_PROVIDERIDS'
  );
  const mnrFutureMockProviderIds = getLagoonConfigValue(
    allConfigs,
    'MNR_MOCK_PROVIDERIDS'
  );
  const minimumProvidersReturn = getLagoonConfigValue(
    allConfigs,
    'MIN_PROVIDER_COUNT_OTHER'
  );
  const excludeNatAncForNetworkIds = getLagoonConfigValue(
    allConfigs,
    'EXCLUDE_NAT_ANC_FOR_NETWORK_IDS'
  );

  const currentMember = getCurrentMember();
  const medicalCoverage = getMemberHealthCoverageType(
    currentMember?.eligibility,
    CoverageTypesCodes.MEDICAL
  );
  const productCode = medicalCoverage?.productCode;
  const marketType = medicalCoverage?.marketType;

  const [snackCardProviderResults, getSnackCardProviderResults] =
    useSnackCardProviderSearch({
      onCompleted: variables.onCompleted,
      onError: variables.onError,
    });

  const {
    ddpCode,
    demographics,
    lineOfBusiness: memberLob,
    population,
    policyNumber,
  } = currentMember;
  const dateOfBirth = demographics?.dateOfBirth;
  const gender = demographics?.gender;

  const reciprocityId = getNetworkIdsForPES(
    currentMember,
    coverageType,
    featureFlags
  );
  const isCustomPreferredPolicy: boolean = getIsCustomPreferredPolicy(
    customPreferredPolicies,
    policyNumber,
    reciprocityId
  );

  const memCatergory = currentMember?.membershipCategory || '';
  const membershipCategory = uspToggleFlag ? memCatergory : null;

  const isPreEffective = useCurrentMemberIsPreEffective();
  const currentMemberEffectiveDate = getCoverageEffDateRange(
    currentMember,
    coverageType
  ).startDate;

  useEffect(() => {
    getSnackCardProviderResults({
      variables: {
        locale,
        longitude,
        latitude,
        stateCode,
        situsState: getSitusState(currentMember, getLob(memberLob, storeLob)),
        pcpIndicator:
          disablePCPIndAndANP && isPCPLandingPage ? null : pcpIndicator,
        isPCPLandingPage,
        useNewAgeAndGenderRollups: disablePCPIndAndANP,
        includeSpecialityRollupCodes: includeSpecialityRollupCodes ?? '',
        includeSpecRollupCodesArray: includeSpecRollupCodesArray ?? '',
        providerType: providerType ?? '',
        lob: getLob(memberLob, storeLob),
        population,
        membershipCategory,
        planYear: getMemberPlanYear(isPreEffective, currentMemberEffectiveDate),
        memberEffectiveDate: isPreEffective ? currentMemberEffectiveDate : null,
        planVariationCode: getPlanVariationCode(currentMember, coverageType),
        claimSystemTypeCode: getClaimOrEligibilitySystemTypeCode(
          currentMember,
          coverageType
        )?.claimSystemTypeCode,
        eligibilitySystemTypeCode: getClaimOrEligibilitySystemTypeCode(
          currentMember,
          coverageType
        )?.eligibilitySystemTypeCode,
        reciprocityId: getNetworkIdsForPES(
          currentMember,
          coverageType,
          featureFlags
        ),
        rollupCodeMap,
        rulesPackageKey: getRulesPackageKey(
          currentMember,
          tierBenefits,
          providerType,
          uspTierOneFlag
        ),
        snpType: conditionalValueRender(
          coverageType == ReverseCoverageTypesCodes.MEDICAL,
          medicalCoverage?.snpType,
          ''
        ),
        coverages: getCoverageTypes(currentMember),
        policyNumber,
        // remove distance limit for Virtual Care filter for BH providers ,
        // backend logic will handle -1 for no distance limit search,
        searchRadius:
          coverageType === 'B' && virtualCare === true
            ? -1
            : getSearchRadius(searchRadius),
        autoIncrementRadius: getAutoIncrementRadius(autoIncrementRadius),
        minimumProvidersReturn: minimumProvidersReturn || undefined,
        memberDemographics: {
          gender,
          dateOfBirth,
        },
        coverageType,
        onlineRetailers: getOnlineRetailers(onlineRetailers),
        useMedicalNewRollUpCodes,
        useBHNewRollUpCodes,
        useVisionNewRollUpCodes,
        useDentalNewRollUpCodes,
        isFutureTerminationDemo,
        terminationProviderIdsDemo,
        enableMockProviderIdsForPreEffective,
        enableTierOneMockProviderIds: enableTierOneMockProviderIds || false,
        futureStartDateProviderIds: getProviderIds(futureStartDateProviderIds),
        tierOneMockProviderIds: getProviderIds(tierOneMockProviderIds),
        acoMockProviderIds: getProviderIds(acoMockProviderIds),
        premiumCareMockProviderIds: getProviderIds(premiumCareMockProviderIds),
        mnrFutureMockProviderIds: getProviderIds(mnrFutureMockProviderIds),
        excludeNatAncForNetworkIds: getExcludeNatAncForNetworkIds(
          excludeNatAncForNetworkIds
        ),
        shouldGetHGData,
        ddpCode,
        aoeCodes,
        behavioralProgramId,
        virtualCare,
        isCustomPreferredPolicy,
        cesProductCode: productCode,
        memberMarketType: marketType,
        behavioralFacilityPrograms,
        invokeLlmModel: categoryLlmFlag,
        search,
        sortBy: 'BEST_MATCH',
      },
    });
  }, [
    providerType,
    longitude,
    latitude,
    stateCode,
    pcpIndicator,
    gender,
    dateOfBirth,
    coverageType,
    onlineRetailers,
    search,
  ]);

  return snackCardProviderResults;
};
