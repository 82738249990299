import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Flex } from '@abyss/web/ui/Flex';
import { ResultCount } from '@abyss/web/ui/Pagination';
import { Text } from '@abyss/web/ui/Text';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { adobeLinkTrackEvent } from '../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { adobeModalTrackEvent } from '../../../../common/AdobeTagging/adobeModalTrackEvent';
import {
  AdobeEventMethods,
  Constants,
  ReverseCoverageTypesCodes,
} from '../../../../common/Constants';
import { FeatureFlags } from '../../../../common/ConstantsFeatureFlags';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { phoneOnly } from '../../../../common/ConstantsStyles';
import { DataCardContainer } from '../../../../common/DataCard/DataCardContainer';
import { MapDisplay } from '../../../../common/MapDisplay';
import { getGeoLocationFromStorage } from '../../../../common/PSXHeader/SearchBar/utils';
import { getFeatureFlag } from '../../../../common/Utils';
import {
  convertCoverageType,
  convertProviderTypeToAdobeType,
  getIndicatorsForImpressions,
  getTier1Indicator,
} from '../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { CountySearchContext } from '../../../../context/CountySearchContext';
import { useAdobePageTrackEvent } from '../../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { useLagoon } from '../../../../hooks/useLagoon';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { CompareProvider } from '../../../../models/Provider';
import { ResponseHeaders } from '../../../../models/ResponseHeaders';
import { Directions } from '../../../../models/RouteDirections';
import { returnSuppressFlag } from '../../../../utils/featureSuppress';
import { getRoute } from '../../../../utils/map.utils';
import { getSuppressFacilityFlags } from '../../../../utils/providerDetails.utils';
import {
  getCurrentMember,
  getLoggedInMember,
} from '../../../../utils/user.utils';
import { useConfig } from '../../context/Analytics';
import { AllPaginationProps } from './AllPaginationProps';
import { BottomDataCard } from './BottomDataCard';
import { CompareDrawerProps } from './CompareDrawerProps';
import { DataCardList, DataCardListStyled } from './DataCardList';
import {
  EnhancePaginationStyles,
  EnhanceStackWrapper,
  ListWrap,
  ListWrapColumn,
  MapDataPagination,
  MapViewWrap,
  MapviewWrapper,
} from './MapView.styled';
import { MapViewHeader } from './MapViewHeader';
import { MapViewHeaderEnhancement } from './MapViewHeaderEnhancement';
import { MapViewTopPagination } from './MapViewTopPagination';
import { MobileMapFilter } from './MobileMapFilter';
import { Popup } from './Popup';
import { SidePaneCloseButton } from './SidePaneCloseButton';
import {
  getDataCardMinHeight,
  getFinalProviderResults,
  getSidePanelArrowIcon,
  mapViewupdatePin,
} from './utils';

type Props = {
  previousLocation: string;
  setPreviousLocation: (a: string) => void;
  allPaginationProps: AllPaginationProps;
  compareDrawerProps: CompareDrawerProps;
  headers: ResponseHeaders;
  isLoading?: boolean;
  isPageNav?: boolean;
  openShare?: boolean;
  results: any[];
  sectionType?: string;
  selectedCheckbox?: any;
  totalResultsCount?: number;
  addSelectedProvider: (a: any) => void;
  setOpenMapview: (a: boolean) => void;
  setMobileRouteView: (a: boolean) => void;
  mobileRouteView: boolean;
  setOpenShare: (a: boolean) => void;
  setSelectedCheckbox: (a: { checked: {} }) => void;
  setSelectedItems: (a: CompareProvider[]) => void;
  setOpenBoxContents: any;
  search?: string | null;
  selectedFilters?: string;
  providerType?: string;
  searchTerm?: string;
  navigateToDirections?: boolean;
  setNavigateToDirections?: (a: boolean) => void;
  openMapview: boolean;
  coverageType: string;
  directions?: Directions;
  setDirections: (a: Directions) => void;
  routeEndCoords?: [number | null, number | null];
  setRouteEndCoords(coords: [number | null, number | null]): void;
  searchMethod?: string;
  isMNR?: boolean;
  heading?: string;
  enableMapViewEnhancements: boolean;
  choosePCP: boolean;
};

const getPageHeader = (
  t: (content: string) => string,
  heading: string | undefined,
  search: string | null | undefined,
  sectionType: string
) => {
  const translatedText = heading?.length
    ? t(`${heading}`)
    : t(`${search}`) || '';

  return `${t(`${sectionType}-results-for`)} ${translatedText}`;
};

export const MapView = ({
  previousLocation,
  setPreviousLocation,
  allPaginationProps,
  compareDrawerProps,
  headers,
  heading,
  isLoading,
  choosePCP,
  isPageNav,
  openShare = false,
  results = [],
  sectionType = '',
  selectedCheckbox = { checked: {} },
  addSelectedProvider,
  setOpenMapview,
  setMobileRouteView,
  mobileRouteView,
  setOpenShare,
  setSelectedCheckbox,
  setSelectedItems,
  setOpenBoxContents,
  search,
  selectedFilters,
  providerType,
  searchTerm,
  navigateToDirections = false,
  setNavigateToDirections,
  openMapview = false,
  coverageType,
  directions,
  setDirections,
  setRouteEndCoords,
  routeEndCoords = [null, null],
  searchMethod,
  totalResultsCount,
  isMNR,
  enableMapViewEnhancements,
}: Props) => {
  const [showMapCollapse] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.SHOW_MAP_COLLAPSE,
  ]);
  const {
    selectedItems = [],
    map,
    openCompare,
    setOpenCompare,
  } = compareDrawerProps;

  const listRef = useRef<HTMLDivElement>(null);
  const topPaginationRef = useRef<HTMLDivElement>(null);
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const mapboxOnpremEnabled = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_ONPREM_MAPBOX
  );
  const [isPageTracked, setPageTracked] = useState<boolean>(false);
  const memberHasRPK = useStore(StoreKeys.TIER1_PLAN_FLAG);
  const ddpCodeUser = getLoggedInMember()?.ddpCode;
  const suppressFacilityFlags = getSuppressFacilityFlags();
  const { isCountySearch } = useContext(CountySearchContext);
  const showTierProviderTag = getFeatureFlag(
    featureFlags,
    FeatureFlags.FEATURE_FLAG_KEY_CHIP_MAP[
      Constants.CHIPS_CATEGORIES.PRIMARY_CARE
    ].TIER_ONE
  );
  const suppressPremiumCare = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PREMIUM_CARE
  );

  const url = window.location.href;
  const urlCheck: boolean =
    url?.includes('findcare-') || url?.includes('localhost') ? false : true;

  const getPageTrackEventData = () => {
    const currentMember = getCurrentMember();
    const isFromMixedResults = !!searchTerm;
    const pageTrackEvent = {
      pageName: 'overview',
      sitesectionLevel1: Constants.ADOBE_TRACKING.VIEWALL_SITESECTION1,
      sitesectionLevel2: `${convertProviderTypeToAdobeType(
        providerType
      )} results`,
      providerBlock: {
        type: convertCoverageType(coverageType),
      },
      impressionBlock: {
        type: 'provider',
        indicator: getIndicatorsForImpressions(
          results,
          featureFlags,
          false,
          {
            suppressPremiumCare,
            ddpCodeUser,
            suppressFacilityFlags,
            memberHasRPK,
            isCountySearch,
          },
          currentMember
        ),
        providerTier: getTier1Indicator(showTierProviderTag, results),
      },
    };

    // We want the search data block if coming from mixed results or when we change pages.
    // The !isPageTracked is to guard against it being included when we apply filters.
    // In that case searchModified is already posted which has the search data.
    if ((isFromMixedResults && !isPageTracked) || isPageNav) {
      return {
        ...pageTrackEvent,
        searchBlock: {
          term:
            !searchMethod || searchMethod === AdobeEventMethods.Guided
              ? ''
              : searchTerm,
          type: 'provider',
          filters: selectedFilters ?? '',
          results: String(totalResultsCount ?? ''),
          method: searchMethod?.toLowerCase() ?? 'guided',
        },
      };
    } else {
      return pageTrackEvent;
    }
  };

  const { adobePageTrackEvent } = useAdobePageTrackEvent(
    getPageTrackEventData()
  );

  const {
    pageSize,
    pageSizeOptions,
    paginationProps,
    nextPage,
    gotoPage,
    previousPage,
    setPageSize,
    setLastPageSize,
    pageNumber,
  } = allPaginationProps;

  const mapViewViewAllLinkTrack = (name: String, loc: String) => {
    adobeLinkTrackEvent({
      name,
      location: loc,
      type: 'internal',
      destinationUrl: '',
    });
  };
  const nextPageTop = () => {
    mapViewViewAllLinkTrack(
      'next page',
      `body:top pagination control:page ${pageNumber}`
    );
    nextPage();
  };
  const nextPageBottom = () => {
    mapViewViewAllLinkTrack(
      'next page',
      `body:bottom pagination control:page ${pageNumber}`
    );
    nextPage();
    topPaginationRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };
  const gotoPageFunc = (page) => {
    mapViewViewAllLinkTrack(
      `page ${page + 1}`,
      `body:bottom pagination control:page ${pageNumber}`
    );
    gotoPage(page);
    topPaginationRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };
  const previousPageTop = () => {
    mapViewViewAllLinkTrack(
      'previous page',
      `body:top pagination control:page ${pageNumber}`
    );
    previousPage();
  };
  const previousPageBottom = () => {
    mapViewViewAllLinkTrack(
      'previous page',
      `body:bottom pagination control:page ${pageNumber}`
    );
    previousPage();
    topPaginationRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const setPageSizeFunc = (pageSizeValue) => {
    mapViewViewAllLinkTrack(
      `results per page:${pageSizeValue}`,
      `body:top pagination control:page ${pageNumber}`
    );
    setLastPageSize(pageSize);
    setPageSize(pageSizeValue);
    gotoPage(0);
  };
  const [closeSidePanel, setCloseSidePanel] = useState<boolean>(false);
  const sidePanelArrowIcon = getSidePanelArrowIcon(closeSidePanel);

  const mobileScreen = useMediaQuery(phoneOnly);
  const dataCardMinHeight = getDataCardMinHeight(mobileScreen);

  const isOpenCompareShare = openCompare || openShare;
  const { longitude, latitude } = getGeoLocationFromStorage();

  const eapCodeFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.EAP_CODE
  );

  const isBHCare: boolean = coverageType?.includes(
    ReverseCoverageTypesCodes['BEHAVIORAL HEALTH']
  );

  const [popupContent, setPopupContent] = useState<any>('');

  const [selectedId, setSelectedId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    null
  );

  const [disableCost] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.DEMO_FLAG,
  ]);

  useEffect(() => {
    setSelectedId(null);
  }, []);

  const providerLocationId = useMemo(
    () =>
      results?.length > 0
        ? JSON.stringify(results?.map((x) => x.locationId))
        : '',
    [results]
  );

  useEffect(() => {
    if (providerLocationId) {
      adobePageTrackEvent();
      setPageTracked(true);
    }
  }, [providerLocationId]);

  useEffect(() => {
    if (listRef?.current) {
      listRef.current.scrollTop = 0;
    }
    if (mobileScreen && openMapview) {
      setPopupContent(null);
    }
  }, [results]);

  const [mobileMixedResult, setMobileMixedResult] = useState(false);
  useEffect(() => {
    if (mobileScreen && !openMapview) {
      setMobileMixedResult(true);
    }
    if (mobileScreen && openMapview) {
      setPopupContent(null);
      setMobileMixedResult(false);
    }
    if (!mobileScreen) {
      setMobileRouteView(false);
      setMobileMixedResult(false);
    }
  }, [mobileScreen, openMapview]);

  const { t } = useTranslation();
  const pageHeader = getPageHeader(t, heading, search, sectionType);
  const mapViewSectionType = sectionType?.toLowerCase();
  const toggleSidePanel = () => {
    const actionStr = closeSidePanel ? 'open' : 'close';
    adobeLinkTrackEvent({
      name: `sidepanel:${actionStr}`,
      location: `body:map`,
      type: 'internal',
    });
    setCloseSidePanel(!closeSidePanel);
  };

  const updatePin = (practitioner, flyToPin = false) => {
    if (mobileScreen) {
      setPopupContent({ ...practitioner });
      return;
    }
    mapViewupdatePin(
      practitioner,
      flyToPin,
      popupContent,
      setPopupContent,
      map
    );
  };
  const enablePagination: boolean = totalResultsCount < pageSize + 1;

  const finalProviderResults = getFinalProviderResults(selectedItems, results);
  const filteredMapViewProviderWithAddress = finalProviderResults.filter(
    (provider) => provider.address.line?.[0]
  );
  const shouldDisplayMap = map && latitude && longitude && results?.length;

  const mapOnPremKey = useConfig('MAPBOX_ONPREM_KEY');
  const mapKey = useConfig('MAPBOX_KEY');
  const mapOnPremURL = useConfig('MAPBOX_ONPREM_URL');

  const handleClickLocation = async (locationId, locationLng, locationLat) => {
    if (selectedId === locationId && !mobileScreen) return;
    const routeDirections = await getRoute(
      map.current,
      longitude,
      latitude,
      +locationLng,
      +locationLat,
      false,
      'driving-traffic',
      false,
      0,
      mapboxOnpremEnabled,
      mapOnPremKey,
      mapKey,
      mapOnPremURL
    );
    if (routeDirections) setDirections(routeDirections);
    setRouteEndCoords([locationLng, locationLat]);
    setSelectedId(locationId);
    adobeModalTrackEvent(Constants.MAP_VIEW_NAVIGATION_MODAL);
  };

  useEffect(() => {
    if (mobileScreen) setMobileRouteView(!!routeEndCoords[0]);
  }, [routeEndCoords, mobileScreen]);

  return (
    <React.Fragment>
      <MapViewHeader
        eapCodeFlag={eapCodeFlag}
        enableMapViewEnhancements={enableMapViewEnhancements}
        isBHCare={isBHCare}
        isPageTracked={isPageTracked}
        mobileScreen={mobileScreen}
        openCompare={openCompare}
        openShare={openShare}
        pageHeader={pageHeader}
        previousLocation={previousLocation}
        resultSet={results}
        sectionType={sectionType}
        setOpenBoxContents={setOpenBoxContents}
        setOpenCompare={setOpenCompare}
        setOpenShare={setOpenShare}
        setPreviousLocation={setPreviousLocation}
        setSelectedCheckbox={setSelectedCheckbox}
        setSelectedItems={setSelectedItems}
      />
      <MapviewWrapper
        cssProps={{
          enableMapViewEnhancements,
          isOpenCompareShare,
          navigateToDirections,
          openMapview,
        }}
      >
        <EnhanceStackWrapper
          cssProps={{ urlCheck, enableMapViewEnhancements, choosePCP }}
        >
          {!mobileScreen && enableMapViewEnhancements && (
            <Flex>
              <Text
                css={{
                  'abyss-text-root': {
                    marginTop: '24px',
                    marginBottom: '8px',
                  },
                }}
                data-auto-testid="total-count-results-heading"
                data-testid="total-count-results-heading"
              >
                {!enablePagination ? (
                  <ResultCount
                    {...paginationProps}
                    css={{
                      'abyss-pagination-results-text': {
                        border: '0px',
                        margin: '0 2px ',
                        color: '#4B4D4F',
                        fontSize: '16px !important',
                        lineHeight: '20px !important',
                        fontWeight: '400 !important',
                      },
                    }}
                    customNoResultsLabel={t('PAGINATION.NO_RESULTS_LABEL')}
                    customOfLabel={t('PAGINATION.OF_LABEL')}
                    customResultLabel={t(`${mapViewSectionType} result for`)}
                    customResultsLabel={t(`${mapViewSectionType} results for`)}
                    customShowingLabel={' '}
                    pageSize={pageSize}
                    resultsTotalCount={totalResultsCount}
                  />
                ) : (
                  `${totalResultsCount} ${t('of')} ${totalResultsCount} ${t(
                    `${mapViewSectionType} results for`
                  )}`
                )}
              </Text>
              <MapViewHeaderEnhancement
                heading={heading}
                openCompare={openCompare}
                openShare={openShare}
                pageNumber={pageNumber}
                pageSize={pageSize}
                results={results}
                search={search}
                sectionType={sectionType}
                setOpenBoxContents={setOpenBoxContents}
                setOpenCompare={setOpenCompare}
                setOpenShare={setOpenShare}
                setSelectedCheckbox={setSelectedCheckbox}
                setSelectedItems={setSelectedItems}
              />
            </Flex>
          )}

          {!mobileScreen && !enableMapViewEnhancements ? (
            <MapViewTopPagination
              enableMapViewEnhancements={enableMapViewEnhancements}
              nextPageTop={nextPageTop}
              pageSize={pageSize}
              pageSizeOptions={pageSizeOptions}
              paginationProps={paginationProps}
              previousPageTop={previousPageTop}
              setPageSizeFunc={setPageSizeFunc}
              topPaginationRef={topPaginationRef}
            />
          ) : null}
          {!closeSidePanel ? (
            <ListWrapColumn
              cssProps={{ isOpenCompareShare, enableMapViewEnhancements }}
            >
              <ListWrap css={{ marginBottom: '16px' }} ref={listRef}>
                {isLoading ? (
                  <DataCardListStyled>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                      <DataCardContainer
                        isLoading={isLoading}
                        isMNR={isMNR}
                        key={item}
                      />
                    ))}
                  </DataCardListStyled>
                ) : (
                  !mobileScreen &&
                  !mobileRouteView &&
                  !mobileMixedResult && (
                    <DataCardList
                      DataCardStyle={{
                        width: '408px',
                        minHeight: dataCardMinHeight,
                      }}
                      addSelectedProvider={addSelectedProvider}
                      fromMobileListView={false}
                      headers={headers}
                      isLoading={!!isLoading}
                      isMNR={isMNR}
                      isMobileMap={mobileScreen}
                      locationForAnalytics={`${sectionType} results for ${search}`}
                      map={map}
                      navigateToDirections={navigateToDirections}
                      pageNumber={pageNumber}
                      parentRef={listRef}
                      providerResults={results}
                      searchTerm={searchTerm}
                      sectionType={sectionType}
                      selectLocation={handleClickLocation}
                      selectedCheckbox={selectedCheckbox}
                      selectedFilters={selectedFilters}
                      setNavigateToDirections={setNavigateToDirections}
                      setRouteEndCoords={setRouteEndCoords}
                      setSelectedCheckbox={setSelectedCheckbox}
                      showCheckboxes={isOpenCompareShare}
                      updatePin={updatePin}
                    />
                  )
                )}
              </ListWrap>
              {!mobileScreen ? (
                <Flex
                  css={enableMapViewEnhancements && EnhancePaginationStyles}
                >
                  <Flex
                    alignItems="center"
                    css={{ gap: '21px', marginTop: '16px', width: '408px' }}
                    direction="column"
                  >
                    {enableMapViewEnhancements ? (
                      <Flex>
                        {!enablePagination && (
                          <MapViewTopPagination
                            enableMapViewEnhancements={
                              enableMapViewEnhancements
                            }
                            nextPageTop={nextPageTop}
                            pageSize={pageSize}
                            pageSizeOptions={pageSizeOptions}
                            paginationProps={paginationProps}
                            previousPageTop={previousPageTop}
                            setPageSizeFunc={setPageSizeFunc}
                            topPaginationRef={topPaginationRef}
                          />
                        )}
                      </Flex>
                    ) : (
                      <MapDataPagination
                        {...paginationProps}
                        customNextLabel={t('PAGINATION.NEXT')}
                        customPreviousLabel={t('PAGINATION.PREVIOUS')}
                        data-auto-testid="pagination-component"
                        data-testid="pagination-component"
                        gotoPage={gotoPageFunc}
                        nextPage={nextPageBottom}
                        pageSize={pageSize}
                        previousPage={previousPageBottom}
                      />
                    )}
                  </Flex>
                </Flex>
              ) : null}
            </ListWrapColumn>
          ) : null}
        </EnhanceStackWrapper>
        <MapViewWrap
          cssProps={{ enableMapViewEnhancements, urlCheck, choosePCP }}
        >
          {showMapCollapse ? (
            <SidePaneCloseButton
              sidePanelArrowIcon={sidePanelArrowIcon}
              toggleSidePanel={toggleSidePanel}
            />
          ) : null}
          {shouldDisplayMap ? (
            <div
              style={{
                position: mobileScreen ? 'sticky' : 'relative',
                top: mobileScreen ? '60px' : '0',
              }}
            >
              {mobileScreen && !mobileRouteView && popupContent ? (
                <div
                  style={{
                    position: 'absolute',
                    bottom: '50px',

                    zIndex: 999,
                    left: '50%',
                    transform: 'translate(-50%)',
                  }}
                >
                  <BottomDataCard
                    disableCost={disableCost}
                    headers={headers}
                    isMNR={isMNR}
                    locationForAnalytics={`map:${sectionType} results for ${search}`}
                    pageNumber={pageNumber}
                    practitioner={popupContent}
                    sectionType={sectionType}
                    selectLocation={handleClickLocation}
                    selectedFilters={selectedFilters}
                    setPopupContent={setPopupContent}
                  />
                </div>
              ) : null}
              <MapDisplay
                closeSidePanel={closeSidePanel}
                coords={{ latitude, longitude }}
                directions={directions}
                disableCost={disableCost}
                headers={headers}
                isOpenCompareShare={isOpenCompareShare}
                map={map}
                mobileMapControlChildren={
                  <MobileMapFilter
                    setDirections={setDirections}
                    setMobileRouteView={setMobileRouteView}
                    setOpenMapview={setOpenMapview}
                    setRouteEndCoords={setRouteEndCoords}
                    setSelectedId={setSelectedId}
                  />
                }
                mobileRouteView={mobileRouteView}
                mobileScreen={mobileScreen}
                providerResults={filteredMapViewProviderWithAddress}
                routeEndCoords={routeEndCoords}
                selectLocation={handleClickLocation}
                selectedItems={selectedItems}
                setPopupContent={setPopupContent}
                setRouteEndCoords={setRouteEndCoords}
                updatePin={updatePin}
              />
            </div>
          ) : null}
        </MapViewWrap>
      </MapviewWrapper>
      {!mobileScreen && (
        <React.Fragment>
          <Popup
            disableCost={disableCost}
            headers={headers}
            isMNR={isMNR}
            locationForAnalytics={`map:${sectionType} results for ${search}`}
            pageNumber={pageNumber}
            practitioner={popupContent}
            selectLocation={handleClickLocation}
            selectedFilters={selectedFilters}
            updatePin={updatePin}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
