import { styled } from '@abyss/web/tools/styled';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { Modal } from '@abyss/web/ui/Modal';
import { TextInput } from '@abyss/web/ui/TextInput';

export const DrawerHeading = {
  '&.abyss-heading-root': {
    '@screen < $sm': {
      fontSize: '$xl',
    },
  },
};

export const StyledDrawer = styled(Drawer, {
  zIndex: '999 !important',
  '.abyss-modal-header-container': {
    width: 'fit-content',
  },
  '.abyss-modal-content-container': {
    borderRadius: 0,
    width: '408px',
    '@screen < $sm': {
      top: '25px',
      height: 'calc(100vh - 26px)',
      width: '100%',
      borderRadius: '20px 20px 0px 0px',
      color: '$primary1',
      border: '1px solid $primary1',
    },
  },
  '.abyss-modal-close-button': {
    '@screen < $sm': {
      top: '12px',
      right: '12px',
    },
  },
  '.abyss-modal-body': {
    scrollbarWidth: 'none' /* Hide scrollbar for Chrome, Safari and Opera */,
    '::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

export const StyledModal = styled(Modal, {
  zIndex: '999 !important',
  '.abyss-modal-header-container': {
    width: 'fit-content',
  },
  '.abyss-modal-content-container': {
    borderRadius: 0,
    width: '408px',
    dynamic: ({ cssProps: { mobileScreen } }) => ({
      top: mobileScreen ? '0px' : '25px',
    }),
    '@screen < $sm': {
      top: '25px',
      height: 'calc(100vh - 26px)',
      width: '100%',
      borderRadius: '20px 20px 0px 0px',
      color: '$primary1',
      border: '1px solid $primary1',
    },
  },
  '.abyss-modal-close-button': {
    '@screen < $sm': {
      top: '12px',
      right: '12px',
    },
  },
  '.abyss-modal-body': {
    scrollbarColor: 'transparent transparent' /* Hide scrollbar for Firefox */,
    '&.hideScrollBar::-webkit-scrollbar': {
      display: 'contents' /* Hide scrollbar for Chrome, Safari and Opera */,
    },
  },
});

export const FilterWrapper = styled(Flex, {
  flexDirection: 'column',
  flexWrap: 'nowrap',
  paddingBottom: '72px',
  height: '100vh',
  scrollbarColor: 'transparent transparent' /* Hide scrollbar for Firefox */,
  '&.hideScrollBar::-webkit-scrollbar': {
    display: 'contents' /* Hide scrollbar for Chrome, Safari and Opera */,
  },
  '@screen < $sm': {
    marginBottom: '70px',
    height: 'calc(100vh - 30px)',
  },
});

export const BottomSection = styled(Flex, {
  dynamic: ({ cssProps: { enableMapViewEnhancements, mobileScreen } }) => ({
    justifyContent: enableMapViewEnhancements && !mobileScreen ? 'center' : '',
  }),
  position: 'absolute',
  backgroundColor: 'white',
  width: '100%',
  zIndex: '999',
  padding: '$md',
  boxShadow: '0px -4px 12px 0px rgba(25, 25, 26, 0.16)',
  left: 0,
  bottom: 0,
  '@screen < $sm': {
    button: {
      width: '100%',
    },
  },
});

export const ScrollableSection = styled(Flex, {
  '&.abyss-flex-root': {
    overflowY: 'auto',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: '104%',
    '&.abyss-search-input-root': {
      height: 'auto',
    },
    '@screen < $sm': {
      width: '100%',
    },
  },
});

export const StyledSearchInput = styled(TextInput, {
  '.abyss-text-input-label': {
    fontSize: '$md',
    fontWeight: '$medium',
    lineHeight: '20px',
    marginBottom: '14px',
  },
  '.abyss-text-input': {
    padding: '$sm 30px $sm 40px',
    fontSize: '$md',
    color: '$gray8',
    fontWeight: '$semibold',
    lineHeight: '24px',
    borderRadius: '100px',
    '&::placeholder': {
      fontWeight: '$medium',
      color: '#6E7072',
      fontStyle: 'italic',
    },
  },
  '.abyss-text-input-left-element': {
    minWidth: 'auto',
    marginLeft: '12px',
    '.abyss-icon-material': {
      fill: '#19191A',
      height: '24px',
      width: '24px',
    },
  },
});
