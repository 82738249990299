import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial, IconMaterialProps } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';
import React, { ReactNode, useState } from 'react';

import { useNotAvailableUnknownText } from '../../../hooks/useNotAvailableUnknownText';
import { StoreKeys } from '../../../hooks/useStore/state';
import { useStore } from '../../../hooks/useStore/useStore';
import { landingDrawerIcon, phoneOnly } from '../../ConstantsStyles';
import { ModalContainer } from './ModalContainer';
import {
  FlexContainer,
  InfoAlignmentContainer,
  infoSubTextContainer,
  infoTitleContainer,
} from './ProviderLocationsDetails.style';

type Props = {
  icon: string;
  infoTitle: string;
  infoSubtext?: null | string;
  children?: ReactNode;
  variant?: 'filled' | 'outlined';
  showSubTitle?: Boolean;
  isFromVirtualProviderGroup?: boolean;
  showPopover?: boolean;
  popoverContent?: string;
  popoverTitle?: string;
  popoverTestId?: string;
  showModal?: boolean;
};

export const InfoProviderContent = ({
  icon,
  infoTitle,
  infoSubtext,
  children,
  variant,
  showSubTitle = true,
  isFromVirtualProviderGroup,
  showPopover = false,
  popoverContent,
  popoverTitle,
  popoverTestId,
  showModal,
}: Props) => {
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const phoneScreen = useMediaQuery(phoneOnly) || isWidget;
  const infoTitleLowerCase = infoTitle.toLowerCase().split(' ').join('-');
  const [isOpen, setIsOpen] = useState(false);

  const {
    providerLocations: notAvailableOrUnknownText,
    headingStyle,
    descriptionStyle,
  } = useNotAvailableUnknownText();
  const infoSubtextValue = infoSubtext || notAvailableOrUnknownText;

  const doShowModal = showModal && !showPopover;

  return (
    <FlexContainer
      data-auto-testid={`provider-info-container-${
        infoTitleLowerCase.includes('accepting')
          ? 'accepting-patients-status'
          : infoTitleLowerCase
      }`}
      data-testid={`provider-info-container-${
        infoTitleLowerCase.includes('accepting')
          ? 'accepting-patients-status'
          : infoTitleLowerCase
      }`}
    >
      <IconMaterial
        color="$primary1"
        icon={icon as IconMaterialProps['icon']}
        size={20}
        variant={variant}
      />
      <InfoAlignmentContainer>
        <React.Fragment>
          <Flex css={{ gap: '4px' }}>
            <Text
              css={{ ...infoTitleContainer(phoneScreen), ...headingStyle }}
              data-testid={`${infoTitle}-Heading`.replace(/ /g, '-')}
            >
              {infoTitle}
            </Text>
            {showPopover ? (
              <React.Fragment>
                {phoneScreen ? (
                  <Layout.Group>
                    <IconMaterial
                      data-testid="popover-icon"
                      icon="info"
                      onClick={() => setIsOpen(true)}
                      size={16}
                      variant="outlined"
                    />
                  </Layout.Group>
                ) : (
                  <Popover
                    content={popoverContent}
                    data-auto-testid={`${popoverTestId}`}
                    data-testid={`${popoverTestId}`}
                    title={popoverTitle}
                    width={220}
                  >
                    <IconMaterial
                      data-testid="popover-icon"
                      icon="info"
                      size={24}
                      variant="outlined"
                    />
                  </Popover>
                )}
                <Drawer
                  css={landingDrawerIcon}
                  isOpen={isOpen && phoneScreen}
                  onClose={() => setIsOpen(false)}
                  position="bottom"
                  size="200"
                  title={popoverTitle}
                >
                  {popoverContent}
                </Drawer>
              </React.Fragment>
            ) : null}
            {doShowModal ? (
              <ModalContainer
                isOpen={isOpen}
                popoverContent={popoverContent}
                popoverTitle={popoverTitle}
                setIsOpen={setIsOpen}
              />
            ) : null}
          </Flex>
          {showSubTitle && !isFromVirtualProviderGroup
            ? children || (
                <Text
                  css={{
                    ...infoSubTextContainer(phoneScreen),
                    ...descriptionStyle,
                  }}
                  data-testid={`${infoTitle}-subtext-provider-locations-tab`.replace(
                    / /g,
                    '-'
                  )}
                >
                  {infoSubtextValue}
                </Text>
              )
            : null}
        </React.Fragment>
      </InfoAlignmentContainer>
    </FlexContainer>
  );
};
