import { styled } from '@abyss/web/tools/styled';

export const HealthGradesContainerStyled = styled('div', {
  borderTop: '1px solid $cardBorder',
  borderLeft: 'none',
  borderRight: 'none',
});

export const AllLandingPageSectionsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});
