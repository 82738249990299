import { useForm } from '@abyss/web/hooks/useForm';
import { Button } from '@abyss/web/ui/Button';
import { Card } from '@abyss/web/ui/Card';
import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { Flex } from '@abyss/web/ui/Flex';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { PageBody } from '@abyss/web/ui/PageBody';
import { Text } from '@abyss/web/ui/Text';
import { TextInput } from '@abyss/web/ui/TextInput';
import { useState } from 'react';

import { DateTime } from '../../utils/DateTime';

enum Environment {
  local = 'http://localhost:3000',
  int = 'https://findcare-dev.uhc.com',
  uat = 'https://findcare-stage.uhc.com',
  prod = 'https://findcare.uhc.com',
}

export const GuestDeeplinkGenerator = () => {
  const [environment, setEnvironment] = useState<string>('local');
  const [lineOfBusiness, setLineOfBusiness] = useState<string>('E&I');
  const [coverageType, setCoverageType] = useState<string>('M');
  const [policyNumber, setPolicyNumber] = useState<string>('0168504');
  const [planVariationCode, setPlanVariationCode] = useState<string>('0001');
  const [reciprocityId, setReciprocityId] = useState<string>('52');
  const currentYear =
    DateTime.getCurrentDateTimeWithCentralOffset().getFullYear();
  const [coverageEffStartDate, setCoverageEffStartDate] = useState<string>(
    `${currentYear}-01-01`
  );
  const [coverageEffEndDate, setCoverageEffEndDate] = useState<string>(
    `${currentYear}-12-31`
  );

  const [deeplink, setDeeplink] = useState('');

  const form = useForm({
    defaultValues: {
      'dropdown-menu-radios-environment': 'local',
      'dropdown-menu-radios-lob': 'E&I',
      'dropdown-menu-radios-coverage-type': 'M',
    },
  });

  const buildDeeplink = (): any => {
    setDeeplink(`${
      Environment[environment]
    }/guest/deeplink?lineOfBusiness=${encodeURIComponent(
      lineOfBusiness
    )}&policyNumber=${encodeURIComponent(
      policyNumber
    )}&reciprocityId=${encodeURIComponent(
      reciprocityId
    )}&planVariationCode=${encodeURIComponent(
      planVariationCode
    )}&coverageTypeCode=${encodeURIComponent(
      coverageType
    )}&coverageEffStartDate=${encodeURIComponent(
      coverageEffStartDate
    )}&coverageEffEndDate=${encodeURIComponent(coverageEffEndDate)}
    `);
  };

  return (
    <PageBody>
      <Flex
        justify="center"
        style={{ paddingTop: '50px', paddingBottom: '25px' }}
      >
        <FormProvider state={form}>
          <Layout.Stack>
            <Heading>Guest Deeplink Generator</Heading>

            <DropdownMenu
              outline
              label={`Environment: ${environment}`}
              after={<IconSymbol icon="keyboard_arrow_down" />}
              menuItems={[
                {
                  label: `Environment`,
                  model: 'dropdown-menu-radios-lob',
                  value: environment,
                  onChange: setEnvironment,
                  radios: [
                    { label: 'local', value: 'local' },
                    { label: 'int', value: 'int' },
                    { label: 'uat', value: 'uat' },
                    { label: 'prod', value: 'prod' },
                  ],
                },
              ]}
            />

            <DropdownMenu
              outline
              label={`Line Of Business: ${lineOfBusiness}`}
              after={<IconSymbol icon="keyboard_arrow_down" />}
              menuItems={[
                {
                  label: `Line Of Business`,
                  model: 'dropdown-menu-radios-lob',
                  value: lineOfBusiness,
                  onChange: setLineOfBusiness,
                  radios: [
                    { label: 'E&I', value: 'E&I' },
                    { label: 'M&R', value: 'M&R' },
                    { label: 'C&S', value: 'C&S' },
                    { label: 'IFP', value: 'IFP' },
                  ],
                },
              ]}
            />

            <DropdownMenu
              outline
              label={`Coverage Type: ${coverageType}`}
              after={<IconSymbol icon="keyboard_arrow_down" />}
              menuItems={[
                {
                  label: `Coverage Type`,
                  model: 'dropdown-menu-radios-coverage-type',
                  value: coverageType,
                  onChange: setCoverageType,
                  radios: [
                    { label: 'M', value: 'M' },
                    { label: 'BH', value: 'BH' },
                    { label: 'D', value: 'D' },
                    { label: 'V', value: 'V' },
                  ],
                },
              ]}
            />

            <TextInput
              label="Reciprocity Id"
              placeholder="52"
              value={reciprocityId}
              isClearable
              onChange={(e) => setReciprocityId(e.target.value)}
              onClear={() => setReciprocityId('')}
            />

            <TextInput
              label="Policy Number"
              placeholder="0168504"
              value={policyNumber}
              isClearable
              onChange={(e) => setPolicyNumber(e.target.value)}
              onClear={() => setPolicyNumber('')}
            />

            <TextInput
              label="Plan Variation Code"
              placeholder="0001"
              value={planVariationCode}
              isClearable
              onChange={(e) => setPlanVariationCode(e.target.value)}
              onClear={() => setPlanVariationCode('')}
            />

            <TextInput
              label="Coverage Eff Start Date"
              placeholder={`${currentYear}-01-01`}
              value={coverageEffStartDate}
              isClearable
              onChange={(e) => setCoverageEffStartDate(e.target.value)}
              onClear={() => setCoverageEffStartDate('')}
            />

            <TextInput
              label="Coverage Eff End Date"
              placeholder={`${currentYear}-12-31`}
              value={coverageEffEndDate}
              isClearable
              onChange={(e) => setCoverageEffEndDate(e.target.value)}
              onClear={() => setCoverageEffEndDate('')}
            />

            <Button onClick={() => buildDeeplink()}>Generate Deeplink</Button>
          </Layout.Stack>
        </FormProvider>
      </Flex>

      {deeplink.length > 0 && (
        <Flex
          justify="center"
          style={{ paddingTop: '10px', paddingBottom: '10px' }}
        >
          <Card>
            <Card.Section>
              <IconMaterial
                css={{ cursor: 'pointer', marginRight: '2px' }}
                icon="content_copy"
                onClick={() => {
                  navigator.clipboard.writeText(deeplink);
                }}
              />
              <Text size={14}>{deeplink}</Text>
            </Card.Section>
          </Card>
        </Flex>
      )}
    </PageBody>
  );
};
