import { Router } from '@abyss/web/ui/Router';

import { DeeplinkContainer } from './DeeplinkContainer';
import { GuestPlanPicker } from './GuestPlanPicker';
import { ProviderSearchForPortalContainer } from './ProviderSearchForPortalContainer';

export const GuestRoutes = () => {
  return (
    <Router.Routes>
      <Router.Route>
        <Router.Route element={<GuestPlanPicker />} path="/" />
        <Router.Route element={<DeeplinkContainer />} path="/deeplink" />
        <Router.Route
          element={<ProviderSearchForPortalContainer />}
          path="/browse"
        />
      </Router.Route>
    </Router.Routes>
  );
};
