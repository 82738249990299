import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Badge } from '@abyss/web/ui/Badge';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Link } from '@abyss/web/ui/Link';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';
import find from 'lodash/find';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAdobePageTrackEvent } from '../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useCoverageType } from '../../../hooks/useCoverageType';
import { useLagoon } from '../../../hooks/useLagoon';
import { useNotAvailableUnknownText } from '../../../hooks/useNotAvailableUnknownText';
import { StoreKeys } from '../../../hooks/useStore/state';
import { useStore } from '../../../hooks/useStore/useStore';
import { FacilityLocationsResponse } from '../../../models/FacilityDetails';
import { getCurrentMember } from '../../../utils/user.utils';
import {
  Constants,
  FacilityCategory,
  ReverseCoverageTypesCodes,
} from '../../Constants';
import { ConstantsLagoon } from '../../ConstantsLagoon';
import {
  PlatinumBadgeStyle,
  landingDrawerIcon,
  mobileOnly,
  phoneOnly,
} from '../../ConstantsStyles';
import {
  StackDiv,
  headerText,
  qualityText,
  stackDivStyle,
} from '../../ProviderDetailTabs/Quality/Quality.styled';
import { convertProviderTypeToAdobeType } from '../../Utils/adobeTrackUtils/adobeTrackUtils';
import { isCnsMember } from '../../Utils/memberUtils/memberUtils';
import {
  LeapFrogContainer,
  TabContainer,
  leapFrogTextStyle,
} from './FacilityQuality.styled';
import { LeapFrogRating } from './LeapFrogRating/LeapFrogRating';

type Props = {
  providerName: string;
  providerType: string;
  selectedLocation: FacilityLocationsResponse;
  tabTitle?: string;
  organizationCode?: string[];
};

export const FacilityQuality = ({
  providerName,
  providerType,
  selectedLocation,
  tabTitle,
  organizationCode,
}: Props) => {
  const [qualityTabText, setQualityTabText] = useState<string>('');
  const patientSafetyText = useLagoon('facility-detail-quality-tab')();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const coverageType = useCoverageType();

  const facilityAttributes = useLagoon('facility-attributes')();

  const category = facilityAttributes?.find(
    (row) => row?.facilitySubCategoryCode === organizationCode?.[0]
  );

  const lagoonData = useLagoon('ui-messaging')();

  const leapfrogDisclaimerContent = find(lagoonData, {
    key: ConstantsLagoon.LEAPFROG.LEAPFROG_DISCLAIMER_CONTENT,
  });

  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const mobileScreen = useMediaQuery(mobileOnly) || isWidget;
  const phoneScreen = useMediaQuery(phoneOnly);
  const widgetDesktopScreen = !useMediaQuery(phoneOnly) && isWidget;

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: tabTitle,
    sitesectionLevel1: Constants.ADOBE_TRACKING.DETAILS_SITESECTION1,
    sitesectionLevel2: `${convertProviderTypeToAdobeType(
      providerType
    )} details`,
  });

  useEffect(() => {
    if (providerName) adobePageTrackEvent();
  }, [providerName]);

  useEffect(() => {
    if (patientSafetyText) {
      const FACILITY_QUALITY_TAB = patientSafetyText.find(
        (x) => x.key === 'FACILITY_QUALITY_TAB'
      );
      setQualityTabText(FACILITY_QUALITY_TAB?.patientSafetyRating);
    }
  }, [patientSafetyText]);
  const { t } = useTranslation();

  const { facilityProviderSpecialties, isPlatinumProvider, leapFrogRatings } =
    selectedLocation;
  const { hasJCAHOAccreditation } = facilityProviderSpecialties || {};

  const { lineOfBusiness } = getCurrentMember();
  const isCns = isCnsMember(lineOfBusiness);

  const patientSafety = (
    <StackDiv
      css={stackDivStyle(mobileScreen, widgetDesktopScreen)}
      data-testid="facility-quality-tab"
    >
      <TabContainer key={Constants.PROVIDER_DETAILS.QUALITY}>
        <Heading
          css={{
            paddingBottom: '12px',
            ...headerText(mobileScreen),
          }}
          data-auto-testid="facility-quality-tab-patient-safety-ratings-heading"
          data-testid="facility-quality-tab-patient-safety-ratings-heading"
          display="h4"
          offset={1}
        >
          {t('FACILITY_DETAILS.QUALITY_TAB.PATIENT_SAFETY')}
        </Heading>
        <Text
          css={qualityText(mobileScreen)}
          data-auto-testid="facility-quality-tab-patient-safety-ratings-text"
          data-testid="facility-quality-tab-patient-safety-ratings-text"
        >
          {t(qualityTabText)}
          <Link
            css={{
              'abyss-link-root': {
                display: 'inline',
                fontSize: '$lg',
              },
            }}
            data-auto-testid="my-benefits-get-a-ride"
            data-testid="my-benefits-get-a-ride"
            href="https://www.leapfroggroup.org/ratings-reports"
          >
            <Text color="$info1" css={qualityText(mobileScreen)}>
              {' '}
              {t('FACILITY_DETAILS.QUALITY_TAB.LEAPFROG_GROUP')}
            </Text>
          </Link>
        </Text>
      </TabContainer>
    </StackDiv>
  );

  const leapFroPopoverContent = t(
    'FACILITY_DETAILS.QUALITY_TAB.LEAPFROG_RATING_INFORMATION_CONTENT'
  );

  const leapFrogPopoverText = t(
    'FACILITY_DETAILS.QUALITY_TAB.LEAPFROG_RATING_INFORMATION'
  );

  const leapFrogText = (
    <LeapFrogContainer>
      <Heading
        css={{
          '&.abyss-heading-root': {
            display: 'flex',
            alignItems: 'center',
            fontSize: '16px !important',
            lineHeight: '20px !important',
            color: '$interactive1 !important',
            ...(phoneScreen && {
              fontSize: '12.64px !important',
            }),
          },
        }}
        data-auto-testid="leap-frog-disclaimer-heading"
        data-testid="leap-frog-disclaimer-heading"
        display="h6"
        offset={2}
      >
        {t('FACILITY_DETAILS.QUALITY_TAB.LEAPFROG_RATING_INFORMATION')}
        <Drawer
          css={landingDrawerIcon}
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          position="bottom"
          size="200"
          title={t('FACILITY_DETAILS.QUALITY_TAB.LEAPFROG_RATING_INFORMATION')}
        >
          {t(
            'FACILITY_DETAILS.QUALITY_TAB.LEAPFROG_RATING_INFORMATION_CONTENT'
          )}
        </Drawer>
        {phoneScreen ? (
          <IconMaterial
            css={{
              'abyss-icon-material': {
                marginLeft: '4px',
              },
            }}
            data-auto-testid="leap-frog-disclaimer-drawer"
            data-testid="leap-frog-disclaimer-drawer"
            icon="info"
            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
            size={16}
            variant="outlined"
          />
        ) : (
          <React.Fragment>
            <Popover
              content={leapFroPopoverContent}
              css={{
                'abyss-popover-trigger': {
                  display: 'block',
                },
                'abyss-popover-trigger-icon': {
                  marginLeft: '4px',
                },
              }}
              data-auto-testid="leap-frog"
              data-testid="leap-frog"
              position={mobileScreen ? 'top' : 'right'}
              title={leapFrogPopoverText}
              width={500}
            />
          </React.Fragment>
        )}
      </Heading>
      <Text
        css={leapFrogTextStyle(phoneScreen)}
        data-auto-testid="leap-frog-disclaimer-text"
        data-testid="leap-frog-disclaimer-text"
      >
        {leapfrogDisclaimerContent?.message ?? ''}
        <Link
          css={{
            'abyss-link-root': {
              display: 'inline',
            },
          }}
          data-auto-testid="leap-frog-hospital-safety"
          data-testid="leap-frog-hospital-safety"
          href="http://www.hospitalsafetyscore.org"
        >
          <Text color="$info1" size={mobileScreen ? '14.22px' : '16px'}>
            {' '}
            http://www.hospitalsafetyscore.org.
          </Text>
        </Link>
      </Text>
    </LeapFrogContainer>
  );

  const ratings = <LeapFrogRating leapFrogRatings={leapFrogRatings} />;

  const leapFrogRatingsBlock = (
    <React.Fragment>
      {patientSafety}
      {mobileScreen ? (
        <StackDiv css={stackDivStyle(mobileScreen, widgetDesktopScreen)}>
          {ratings}
          {leapFrogText}
        </StackDiv>
      ) : (
        <React.Fragment>
          <StackDiv css={stackDivStyle(mobileScreen, widgetDesktopScreen)}>
            {ratings}
          </StackDiv>
          <StackDiv css={stackDivStyle(mobileScreen, widgetDesktopScreen)}>
            {leapFrogText}
          </StackDiv>
        </React.Fragment>
      )}
    </React.Fragment>
  );

  const notAvailableOrUnknownText = useNotAvailableUnknownText();

  return (
    <React.Fragment>
      {!isCns &&
      coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'] &&
      isPlatinumProvider ? (
        <StackDiv css={stackDivStyle(mobileScreen, widgetDesktopScreen)}>
          <TabContainer>
            <Badge css={PlatinumBadgeStyle} variant="neutral">
              {t('PROVIDER_DETAILS.ABOUT_TAB.PLATINUM_PROVIDER')}
            </Badge>
          </TabContainer>
        </StackDiv>
      ) : null}
      {category?.facilityCategory?.toLowerCase() ===
        FacilityCategory.HOSPITAL.toLowerCase() && leapFrogRatings?.length > 0
        ? leapFrogRatingsBlock
        : null}
      <StackDiv css={stackDivStyle(mobileScreen, widgetDesktopScreen, true)}>
        <TabContainer>
          <Heading
            css={{
              paddingBottom: '12px',
              ...headerText(mobileScreen),
            }}
            data-auto-testid="accreditation-heading"
            data-testid="accreditation-heading"
            display="h4"
            offset={1}
          >
            {t('PROVIDER_DETAILS.QUALITY_TAB.ACCREDITATION_TITLE')}
          </Heading>
          {hasJCAHOAccreditation ? (
            <Text
              css={qualityText(mobileScreen)}
              data-testid="facility-accreditation-heading"
            >
              {t(Constants.FACILITY_DETAILS.QUALITY_TAB.ACCREDITATION[0])}
            </Text>
          ) : (
            <Text
              css={qualityText(mobileScreen)}
              data-testid="facility-accreditation-heading"
            >
              {notAvailableOrUnknownText?.facilityDetails}
            </Text>
          )}
        </TabContainer>
      </StackDiv>
    </React.Fragment>
  );
};
