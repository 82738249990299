import { config } from '@abyss/web/tools/config';
import { Router } from '@abyss/web/ui/Router';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { PortalData } from '../../common/Portal';
import { populatePortalDataFromGuestPlanPicker } from './utils';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'abyss-parcel': any;
      'portal-data': any;
    }
  }
}

export const ProviderSearchForPortalContainer = () => {
  const { state } = useLocation();
  const {
    lineOfBusiness,
    policyNumber,
    reciprocityId,
    planVariationCode,
    coverageTypeCode,
    coverageEffStartDate,
    coverageEffEndDate,
  } = state;
  const portalData: PortalData = {
    portalName: 'browse',
    portalConfig: {
      basePath: '/guest',
      headerChipsEnabled: true,
      theme: 'uhc',
      language: 'en-US',
      homePage: '/',
      isGuestUser: true,
      showCost: false,
      showTreatmentCenter: true,
      includeBaseCss: true,
      showVirtualVisits: true,
      showOnlineScheduling: false,
      graphQlUrl: config('GRAPHQL_API_URL'),
    },
    portalMemberInfo: populatePortalDataFromGuestPlanPicker(
      lineOfBusiness,
      policyNumber,
      reciprocityId,
      planVariationCode,
      coverageTypeCode,
      coverageEffStartDate,
      coverageEffEndDate
    ),
    portalUrls: [],
  };

  const parcelUrl = config('PSX_PARCEL_URL');

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = parcelUrl;
    document.head.appendChild(script);
  }, []);

  return (
    <React.Fragment>
      <Router.MetaTags title="Find Care" />
      <abyss-parcel
        import="provider-search-for-portal"
        portal-data={JSON.stringify(portalData)}
      />
    </React.Fragment>
  );
};
