import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import find from 'lodash/find';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { useLagoon } from '../../../../..//hooks/useLagoon';
import { adobeLinkTrackEvent } from '../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../../../../common/Constants';
import { ConstantsLagoon } from '../../../../../common/ConstantsLagoon';
import {
  hideScrollbar,
  mediumScreenOnly,
} from '../../../../../common/ConstantsStyles';
import { MapDisplay } from '../../../../../common/MapDisplay';
import { getGeoLocationFromStorage } from '../../../../../common/PSXHeader/SearchBar/utils';
import { SelectLocationCard } from '../../../../../common/SelectLocationCard';
import { useFeatureFlag } from '../../../../../hooks/useFeatureFlag';
import { ProviderLocation } from '../../../../../models/ProviderDetails';
import { Directions } from '../../../../../models/RouteDirections';
import {
  getRoute,
  setResultIcon,
  setZoomLevel,
  updateIconSize,
} from '../../../../../utils/map.utils';
import { getSelectedLocationIndex } from '../../../../../utils/providerDetails.utils';
import { useConfig } from '../../../context/Analytics';
import {
  ChooseLocationCardsContainer,
  ChooseLocationLabel,
  ChooseLocationRow,
  LocationsWrapColumn,
  MapViewWrap,
  MobileMapViewWrap,
  MobileViewMapButton,
  NewPatientDisclaimer,
  SidePaneCloseButton,
} from './ChoosePCPLocation.styled';

type Props = {
  hasMultipleLocations: boolean;
  isMobileMapView: boolean;
  mobileScreen: boolean;
  providerLocations: ProviderLocation[];
  saveAndCancelButtons: React.ReactNode;
  setIsMobileMapView: (isMobileMapView: boolean) => void;
  useStickyButtonRow: boolean;
  setSelectedAddressIndex: (selectedAddressIndex: number) => void;
  handleBackButtonClick: () => void;
  setMobileRouteView: (isMobileMapView: boolean) => void;
  mobileRouteView: boolean;
  mobileRouteList: boolean;
  setMobileRouteList: (isMobileRouteList: boolean) => void;
  map: any;
  routeEndCoords?: [number | null, number | null];
  setRouteEndCoords(coords: [number | null, number | null]): void;
};

export const LocationsMapViewWrap = ({
  hasMultipleLocations,
  isMobileMapView,
  mobileScreen,
  providerLocations,
  saveAndCancelButtons,
  setIsMobileMapView,
  setSelectedAddressIndex,
  useStickyButtonRow,
  handleBackButtonClick,
  setMobileRouteView,
  mobileRouteView,
  mobileRouteList,
  setMobileRouteList,
  map,
  routeEndCoords,
  setRouteEndCoords,
}: Props) => {
  const [showMapCollapse] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.SHOW_MAP_COLLAPSE,
  ]);
  const [mapboxOnpremEnabled] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_ONPREM_MAPBOX,
  ]);

  const [enableMapEnhancements] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_LIST_VIEW_MAP_ENHANCEMENTS,
  ]);
  const mapOnPremKey = useConfig('MAPBOX_ONPREM_KEY');
  const mapKey = useConfig('MAPBOX_KEY');
  const mapOnPremURL = useConfig('MAPBOX_ONPREM_URL');

  const [closeSidePanel, setCloseSidePanel] = useState<boolean>(false);
  const [directions, setDirections] = useState<Directions>({
    userPlaceName: '',
    endPlaceName: '',
    routes: [],
  });
  const mediumScreen = useMediaQuery(mediumScreenOnly);
  const { t } = useTranslation();
  const { longitude, latitude } = getGeoLocationFromStorage();
  const [highlightId, setHighlightId] = useSessionStorage<any>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_HIGHLIGHT_ID,
    { providerId: '', from: 'mapView' }
  );
  const [selectedId, setSelectedId] = useSessionStorage<string | null>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    null
  );
  const [currentSelectedId, setCurrentSelectedId] = useSessionStorage<
    string | null
  >(Constants.STORAGE_KEYS.SESSION.PROVIDER_MAP_PIN_SELECTED_ID, null);

  const [mapPinCoords] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_COORDS,
    []
  );

  const lagoonData = useLagoon('ui-messaging')();

  const newPatientDisclaimer = find(lagoonData, {
    key: ConstantsLagoon.CHOOSE_PCP_FLOW.NEW_PATIENT_DISCLAIMER,
  });

  const highlightRefs = React.useRef<any>({});
  const mobileMapViewClassName = isMobileMapView
    ? 'location-card-map-view'
    : '';

  useEffect(() => {
    const selectedLocationIndex = getSelectedLocationIndex(
      providerLocations,
      currentSelectedId
    );
    setSelectedId(providerLocations[selectedLocationIndex].locationId);
    setCurrentSelectedId(providerLocations[selectedLocationIndex].locationId);
  }, []);

  useEffect(() => {
    highlightRefs.current[highlightId.providerId]?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  }, [highlightId]);

  useEffect(() => {
    map.current?.resize();
    if (!closeSidePanel) {
      setZoomLevel(map.current, mapPinCoords);
    }
  }, [closeSidePanel]);

  useEffect(() => {
    if (mobileScreen) {
      setMobileRouteView(!!routeEndCoords?.[0]);
      if (!routeEndCoords?.[0] && mobileRouteList) {
        handleBackButtonClick();
        setMobileRouteList(false);
      }
    }
  }, [routeEndCoords, mobileScreen]);

  const handleMapButton = () => {
    setIsMobileMapView(true);
  };

  const selectLocation = (index, location) => {
    setSelectedId(location.locationId);
    setSelectedAddressIndex(index);
  };
  const handleDirectionClick = async (location) => {
    const { longitude: locationLng, latitude: locationLat } = location;
    const routeDirections = await getRoute(
      map.current,
      longitude,
      latitude,
      +locationLng,
      +locationLat,
      mediumScreen,
      'driving-traffic',
      false,
      0,
      mapboxOnpremEnabled,
      mapOnPremKey,
      mapKey,
      mapOnPremURL
    );
    if (routeDirections) setDirections(routeDirections);
    setRouteEndCoords([locationLng, locationLat]);
    if (!isMobileMapView && mobileScreen) {
      setIsMobileMapView(true);
      setMobileRouteList(true);
    }
  };

  const handleLocationCardMouseEnter = async (
    index,
    location,
    flyToPin = false
  ) => {
    if (mobileScreen) return;
    await setZoomLevel(map.current, mapPinCoords);

    if (enableMapEnhancements) {
      const marker = document.querySelector(
        `#custom-marker-${location.locationId}`
      );
      updateIconSize(true, marker, true);
    }
    setResultIcon(
      map.current,
      index,
      true,
      setHighlightId,
      location.locationId
    );
    if (flyToPin) {
      map.current?.flyTo({
        center: [+location.longitude, +location.latitude],
        zoom: Constants.DEVICE_LOCATION.ZOOM,
      });
    }
  };

  const handleLocationCardMouseLeave = (index, location) => {
    if (enableMapEnhancements) {
      const marker = document.querySelector(
        `#custom-marker-${location.locationId}`
      );
      updateIconSize(false, marker, true);
    }
    if (!selectedId) {
      setResultIcon(map.current, index, false, setHighlightId, null);
    }
  };

  const chooseALocationHeading =
    hasMultipleLocations && !isMobileMapView ? (
      <ChooseLocationRow
        data-auto-testid="choose-a-location-row"
        data-testid="choose-a-location-row"
      >
        <ChooseLocationLabel
          color="$primary1"
          display="h4"
          fontWeight="$bold"
          offset={1}
        >
          {t('CHOOSE_PCP_FLOW.CHOOSE_A_LOCATION')}
        </ChooseLocationLabel>
        <MobileViewMapButton
          before={
            <IconMaterial
              color="$interactive1"
              icon="map"
              isScreenReadable
              size="15px"
            />
          }
          data-auto-testid="map-button"
          data-testid="map-button"
          onClick={handleMapButton}
          variant="ghost"
        >
          {t('View Map')}
        </MobileViewMapButton>
      </ChooseLocationRow>
    ) : null;

  return (
    <MobileMapViewWrap
      className={isMobileMapView ? 'show-mobile-map-view' : ''}
      cssProps={{ mobileRouteView }}
    >
      <LocationsWrapColumn
        className="locations-wrap-column"
        css={hideScrollbar}
      >
        {(!closeSidePanel || mobileScreen) && !mobileRouteView ? (
          <Layout.Stack alignItems="left" grow space={12}>
            {chooseALocationHeading}
            <ChooseLocationCardsContainer
              aria-label="Choose a location"
              className="choose-location-container"
              role="group"
            >
              {providerLocations?.map((location, index) => (
                <div
                  className="location-cards-container"
                  data-auto-testid="choose-pcp-location-section"
                  data-testid="choose-pcp-location-section"
                  key={`pcp-location-${index + 1}`}
                  onBlur={() => handleLocationCardMouseLeave(index, location)}
                  onFocus={() => handleLocationCardMouseEnter(index, location)}
                  onMouseEnter={() =>
                    handleLocationCardMouseEnter(index, location)
                  }
                  onMouseLeave={() =>
                    handleLocationCardMouseLeave(index, location)
                  }
                >
                  {location?.acceptingNewPatients && (
                    <SelectLocationCard
                      className={`
                      ${mobileMapViewClassName} ${
                        highlightId.providerId === location.locationId
                          ? 'highlight'
                          : ''
                      }
                    `}
                      handleDirectionClick={() =>
                        handleDirectionClick(location)
                      }
                      highlightRefs={highlightRefs}
                      isMobileMapView={isMobileMapView}
                      location={location}
                      selectLocation={() => selectLocation(index, location)}
                      selected={
                        hasMultipleLocations &&
                        selectedId === location.locationId
                      }
                      setIsMobileMapView={setIsMobileMapView}
                      showBadgeRow
                      showRadioSelect={hasMultipleLocations}
                    />
                  )}
                </div>
              ))}
            </ChooseLocationCardsContainer>

            {!isMobileMapView ? (
              <NewPatientDisclaimer
                data-auto-testid="patient-disclaimer"
                data-testid="patient-disclaimer"
              >
                <Text fontWeight="$medium" size={12.64}>
                  {newPatientDisclaimer?.message ?? ''}
                </Text>
              </NewPatientDisclaimer>
            ) : null}
            {!useStickyButtonRow ? saveAndCancelButtons : null}
          </Layout.Stack>
        ) : null}
        {showMapCollapse ? (
          <SidePaneCloseButton
            alignItems="center"
            className="side-btn"
            justify="center"
          >
            <IconMaterial
              css={{
                fill: '$black',
              }}
              data-auto-testid="sidepanel-close-icon"
              data-testid="sidepanel-close-icon"
              icon={
                closeSidePanel ? 'keyboard_arrow_right' : 'keyboard_arrow_left'
              }
              onClick={() => {
                adobeLinkTrackEvent({
                  name: `sidepanel:${closeSidePanel ? 'open' : 'close'}`,
                  location: 'body:map',
                });
                setCloseSidePanel(!closeSidePanel);
              }}
              size="$md"
            />
          </SidePaneCloseButton>
        ) : null}
      </LocationsWrapColumn>
      <MapViewWrap cssProps={{ closeSidePanel, mobileRouteView }}>
        {providerLocations?.length ? (
          <MapDisplay
            coords={{ longitude, latitude }}
            directions={directions}
            locationResults={providerLocations}
            map={map}
            mobileScreen={mobileScreen}
            refToBeFocusedOnDirectionsClose={
              highlightRefs.current[highlightId.providerId] as HTMLElement
            }
            routeEndCoords={routeEndCoords}
            setRouteEndCoords={setRouteEndCoords}
          />
        ) : null}
      </MapViewWrap>
    </MobileMapViewWrap>
  );
};
