import { useShallow } from 'zustand/react/shallow';
import {
  Constants,
} from '../../common/Constants';
import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../common/Utils';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { useLagoon } from '../useLagoon';

export const useCategoryLLMFlag = (chipCategory?: string) => {

  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();

  const { chipValue } = useChipStore(
    useShallow((state: ChipState) => ({
      chipValue: state.chipValue,
    }))
  );

  const categoryLLMFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_LLM + "_" + (chipCategory || chipValue)?.toUpperCase()?.split(" ").join('_')
  );

  return categoryLLMFlag
};
