import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Slider } from '@abyss/web/ui/Slider';
import { t } from 'i18next';
import { useEffect, useState } from 'react';

import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { FilterCollectionModel, FilterOptionsType } from '../Constants';
import { DistanceInfoIcon } from '../RadioGroup/DistanceInfoIcon';

const Container = styled('div', {
  background: '$primary2',
  marginTop: '8px',
  padding: '10px 20px 10px 15px',
});
const buttonStyles: any = {
  'abyss-button-root': {
    height: '42px',
    marginTop: '20px',
    border: 'none',
    borderRadius: '24px',
    fontWeight: 600,
  },
};
const CustomSlider = styled(Slider, {
  '&.abyss-slider-track': {
    backgroundColor: '$white',
    border: '1px solid $sapphireDvz1',
  },
  '&.abyss-slider-track-filler': {
    backgroundColor: ' $accent1',
  },
});
interface Props {
  options: number[] | string[] | boolean[] | any[];
  defaultValue?: string | number | boolean;
  model: string;
  submitBtnName?: string;
  disableSubmitBtn?: boolean;
  onOptionChange?: (any) => void;
  onApply?: (value: string | number | boolean) => void;
  locationForAnalytics?: string;
}
export const FilterSlider = ({
  options,
  defaultValue,
  model,
  submitBtnName,
  disableSubmitBtn = false,
  onOptionChange = () => {},
  onApply = () => {},
  locationForAnalytics,
}: Props) => {
  const [sliderValue, setSliderValue] = useState(
    defaultValue &&
      options.filter((option) => option.value === defaultValue).length > 0
      ? options.filter((option) => option.value === defaultValue)?.[0].c_value
      : 0
  );

  useEffect(() => {
    setSliderValue(
      defaultValue &&
        options.filter((option) => option.value === defaultValue).length > 0
        ? options.filter((option) => option.value === defaultValue)?.[0].c_value
        : 0
    );
  }, [defaultValue]);

  const onChangeSliderOption = (value) => {
    if (value) {
      adobeLinkTrackEvent({
        name: options.filter((option) => value === option.value.toString())?.[0]
          ?.dnt_label,
        location: locationForAnalytics,
        type: FilterOptionsType.SLIDER,
      });
    }
    setSliderValue(value);
    onOptionChange({
      [model]: options.filter((option) => value === option.c_value),
    });
  };

  const handleSubmit = () => {
    onApply(sliderValue);
  };

  return (
    <Container
      data-auto-testid="filter-slider-section"
      data-testid="filter-slider-section"
    >
      <CustomSlider
        customMarkLabel={(val) =>
          options
            .find((mark) => mark.c_value === val)
            .label.replaceAll('miles', '')
            .replaceAll('mile', '')
        }
        data-auto-testid="filter-slider"
        data-testid="filter-slider"
        disablePopover
        maxValue={6}
        onChange={(val) => {
          onChangeSliderOption(val);
        }}
        showMarks
        subText={`${t('CONTENT_FILTERS.MILE')}s`}
        value={sliderValue}
      />
      {model === FilterCollectionModel.DISTANCE ? <DistanceInfoIcon /> : null}
      {submitBtnName ? (
        <Button
          css={buttonStyles}
          data-auto-testid="apply-button"
          data-testid={`${model}-apply-button`}
          isDisabled={disableSubmitBtn}
          onClick={handleSubmit}
          type="submit"
        >
          {submitBtnName}
        </Button>
      ) : null}
    </Container>
  );
};
