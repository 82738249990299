import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { storage } from '@abyss/web/tools/storage';
import { Button } from '@abyss/web/ui/Button';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { SearchFilterContext } from '../../context/SearchFilterContext';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { Option } from '../../models/ProviderSearch';
import { Constants } from '../Constants';
import { phoneOnly } from '../ConstantsStyles';
import { handleLinkAndModalTrack } from '../Utils/adobeTrackUtils';
import { AllFilterDrawer } from './AllFilterDrawer';

type Props = {
  name: string;
  size: string;
  color?: string;
  icon?: string;
  variant?: string;
};

export const ResultFilter = ({
  name,
  size,
  color = '$primary1',
  icon = 'tune',
  variant = 'outline',
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const mobileScreen = useMediaQuery(phoneOnly);
  const { searchFilters } = useContext(SearchFilterContext);
  const [selectedFiltersCount, setSelectedFiltersCount] = useState<number>(0);
  const [enableMapViewEnhancements] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_LIST_VIEW_MAP_ENHANCEMENTS,
  ]);

  const resultFilterDataTestId: string = 'result-filter';

  let properties: any = {
    buttonStyles: {
      backgroundColor:
        selectedFiltersCount > 0 && !enableMapViewEnhancements
          ? '#CCF2F7'
          : '$primary2',
      border: '1px solid $gray4',
      padding: '$sm $lg $sm $md',
      height: '2.625em',
      fontWeight: '$semibold',
      borderRadius: '24px',
      color: '$gray8',
    },
    drawerSize: 492,
    drawerposition: 'right',
  };

  if (mobileScreen) {
    properties = {
      buttonStyles: {
        backgroundColor: selectedFiltersCount > 0 ? '$tint1' : '$primary2',
        boxShadow:
          selectedFiltersCount > 0 ? '0 2px rgba(25, 25, 26, 0.16)' : 'unset',
        border: 'solid 2px $primary1',
        '&:active, &:focus, &:hover': {
          backgroundColor: '#CCF2F7',
        },
        whiteSpace: 'nowrap',
      },
      drawerSize: window.innerHeight - 15,
      drawerPosition: 'bottom',
    };
  }

  useEffect(() => {
    const selectedOptions: { label: string; model: string }[] = [];

    Object.keys(searchFilters).forEach((model) => {
      if (Array.isArray(searchFilters[model])) {
        searchFilters[model].forEach((option: Option) => {
          if (!option.hide) {
            selectedOptions.push({ label: option.label, model });
          }
        });
      } else {
        const option: Option = searchFilters[model];
        if (option.value && !option.hide) {
          selectedOptions.push({ label: option.label, model });
        }
      }
    });
    setSelectedFiltersCount(selectedOptions.length);
  }, [JSON.stringify(searchFilters)]);

  const handleOnClickResultFilter = () => {
    const linkName: string = 'all filters';
    const linkLocation: string = `body:${Constants.PROVIDER_RESULTS.FILTER_BY}`;
    const modalName: string = `${linkName} drawer`;
    handleLinkAndModalTrack(linkName, linkLocation, modalName);
    storage.session.set('allFiltersOpen', true);
    setIsOpen(true);
  };

  const allFiltersButtonRef = useRef<HTMLButtonElement>(null);
  const handleCloseAllFilterDrawer = () => {
    storage.session.set('allFiltersOpen', false);
    setIsOpen(false);
    allFiltersButtonRef.current?.focus();
  };

  return (
    <React.Fragment>
      {isOpen ? (
        <AllFilterDrawer
          allFiltersButtonRef={allFiltersButtonRef}
          isOpen={isOpen}
          onClose={handleCloseAllFilterDrawer}
          position={properties.drawerPosition}
          size={properties.drawerSize}
        />
      ) : null}
      <Button
        css={{ 'abyss-button-root': { ...properties.buttonStyles } }}
        data-auto-testid={resultFilterDataTestId}
        data-testid={resultFilterDataTestId}
        onClick={handleOnClickResultFilter}
        ref={allFiltersButtonRef}
        size={size}
        variant={variant}
      >
        <Layout.Group>
          <IconMaterial color={color} icon={icon} />
          <Text color={color} fontWeight={mobileScreen && 600}>
            {name} {selectedFiltersCount ? `(${selectedFiltersCount})` : ''}
          </Text>
        </Layout.Group>
      </Button>
    </React.Fragment>
  );
};
