import { GuestMemberInfo, GuestPortalMemberInfo } from "../../common/Portal/GuestPortalMemberInfo";
import { Coverage, CoverageEffDateRange, Eligibility, PortalMemberInfo, TypeCode } from "../../common/Portal/PortalMemberInfo";

const coverageTypeCodeToDescMapping = {
  "M": "MEDICAL",
  "B": "BEHAVIORAL HEALTH",
  "D": "DENTAL",
  "V": "VISION"
}

export const populatePortalDataFromGuestPlanPicker = (
  lineOfBusiness: string,
  policyNumber: string,
  reciprocityId: string,
  planVariationCode: string,
  coverageTypeCode: string,
  coverageEffStartDate: string,
  coverageEffEndDate: string

): GuestPortalMemberInfo | PortalMemberInfo => {
  const guestCoverageEffDateRange: CoverageEffDateRange = {
    startDate: coverageEffStartDate,
    endDate: coverageEffEndDate,
  }
  const guestTypeCode: TypeCode = {
    desc: coverageTypeCodeToDescMapping[coverageTypeCode] ?? '',
    coverageTypeCode
  }
  const guestCoverageType: Coverage = {
    coverageEffDateRange: guestCoverageEffDateRange,
    planVariationCode,
    typeCode: guestTypeCode,
    reciprocityId,
  }
  const guestEligibility: Eligibility = {
    memberHealthCoverage: {
      coverageType: [guestCoverageType]
    }
  }
  const guestMemberInfo: GuestMemberInfo = {
    eligibility: [guestEligibility],
    lineOfBusiness,
    policyNumber,
  }
  return {
    memberInfo: [guestMemberInfo]
  }
}
