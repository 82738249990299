import { storage } from '@abyss/web/tools/storage';
import { GuestMemberInfo } from '#/common/Portal/GuestPortalMemberInfo';
import { MemberInfo } from '#/common/Portal/PortalMemberInfo';

import { Constants } from '../../common/Constants';

export const getOhbsPolicyKey = (memberInfo) => {
  const ohbsCoverage = {
    subscriberId: '',
    groupId: '',
  };
  memberInfo.forEach((member) => {
    member?.eligibility?.forEach((eligibility) => {
      if (
        eligibility?.policyKey?.includes('BEHAVIORAL') &&
        eligibility?.memberHealthCoverage?.coverageType?.length > 0
      ) {
        const subScriderId =
          eligibility?.memberHealthCoverage?.coverageType[0]?.coverageID.filter(
            (subcId) =>
              subcId?.sourceSysCode?.includes('BEHAVIORAL SUBSCRIBER ID')
          );
        ohbsCoverage.subscriberId = subScriderId[0]?.id;
        ohbsCoverage.groupId =
          eligibility?.memberHealthCoverage?.coverageType[0]?.groupNumber;
      }
    });
  });

  return ohbsCoverage.subscriberId?.length > 0 &&
    ohbsCoverage.groupId.length > 0
    ? ohbsCoverage
    : null;
};

export const setBhReciprocityId = (memberInfo: MemberInfo[]) => {
  memberInfo.forEach((member) => {
    member.lineOfBusiness === 'OBH'
      ? (member.bhReciprocityId =
          member.eligibility[0].memberHealthCoverage.coverageType[0].reciprocityId)
      : null;
  });
};

export const setGuestMemberInfo = (guestMemberInfo: GuestMemberInfo[]) => {
  guestMemberInfo.forEach((guestMember) => {
    storage.session.set(Constants.STORAGE_KEYS.SESSION.GUEST_USER, guestMember);
  });
};
