import { Flex } from '@abyss/web/ui/Flex';
import React from 'react';

import { LocateMe } from './LocateMe';
import { MapControlContainer } from './MapDisplay.styled';

type Props = {
  map: any;
  controlChildren?: React.ReactNode;
  mobileRouteView?: boolean;
  enableMapEnhancements?: boolean;
};

export const MapControlMobile = ({
  map,
  controlChildren,
  mobileRouteView = false,
  enableMapEnhancements = false,
}: Props) => (
  <MapControlContainer
    alignItems="end"
    css={{
      top: mobileRouteView ? '260px' : '$md',
      right: !enableMapEnhancements ? '$md' : 'auto',
      left: enableMapEnhancements ? '$md' : 'auto',
    }}
    direction={enableMapEnhancements ? 'row' : 'column'}
  >
    {controlChildren}
    <Flex
      css={{
        '@screen < $sm': {
          marginLeft: enableMapEnhancements ? '50px' : 'auto',
        },
        '@media (min-width: 400px)': {
          marginLeft: enableMapEnhancements ? '5rem' : 'auto',
        },
      }}
    >
      <LocateMe map={map} />
    </Flex>
  </MapControlContainer>
);
