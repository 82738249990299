import { useForm } from '@abyss/web/hooks/useForm';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Button } from '@abyss/web/ui/Button';
import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { Flex } from '@abyss/web/ui/Flex';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Heading } from '@abyss/web/ui/Heading';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { PageBody } from '@abyss/web/ui/PageBody';
import { useState } from 'react';

import { DateTime } from '../../utils/DateTime';
import plans from './mockPlans.json';

interface Plan {
  name: string;
  policyNumber: string;
  reciprocityId: string;
  planVariationCode: string;
}

export const GuestPlanPicker = () => {
  const { navigate } = useRouter();
  const currentYear =
    DateTime.getCurrentDateTimeWithCentralOffset().getFullYear();
  const [lineOfBusiness, setLineOfBusiness] = useState<string>('E&I');
  const [coverageType, setCoverageType] = useState<string>('M');
  const [planName, setPlanName] = useState<string>('Choice Example');
  const [planYear, setPlanYear] = useState<number>(currentYear);

  const selectedPlan: Plan = plans[lineOfBusiness].find(
    (plan) => plan.name === planName
  );

  const form = useForm({
    defaultValues: {
      'dropdown-menu-radios-lob': 'E&I',
      'dropdown-menu-radios-coverage-type': 'M',
      'dropdown-menu-radios-selected-plan': 'Choice Example',
      'dropdown-menu-radios-plan-year': currentYear,
    },
  });

  const buildPortalData = (): any => {
    const { policyNumber, reciprocityId, planVariationCode } = selectedPlan;
    let coverageEffStartDate,
      coverageEffEndDate = '';
    if (planYear === currentYear) {
      coverageEffStartDate = `${currentYear}-01-01`;
      coverageEffEndDate = `${currentYear}-12-31`;
    } else {
      coverageEffStartDate = `${currentYear + 1}-01-01`;
      coverageEffEndDate = `${currentYear + 1}-12-31`;
    }
    return {
      lineOfBusiness,
      policyNumber,
      reciprocityId,
      planVariationCode,
      coverageTypeCode: coverageType,
      coverageEffStartDate,
      coverageEffEndDate,
    };
  };

  const handleChangeLineOfBusiness = (e) => {
    setPlanName('');
    setLineOfBusiness(e);
  };

  return (
    <PageBody>
      <Flex justify="center" style={{ paddingTop: '50px' }}>
        <FormProvider state={form}>
          <Layout.Stack>
            <Heading>Choose your plan</Heading>
            <DropdownMenu
              outline
              label={`Line Of Business: ${lineOfBusiness}`}
              after={<IconSymbol icon="keyboard_arrow_down" />}
              menuItems={[
                {
                  label: `Line Of Business`,
                  model: 'dropdown-menu-radios-lob',
                  value: lineOfBusiness,
                  onChange: handleChangeLineOfBusiness,
                  radios: [
                    { label: 'E&I', value: 'E&I' },
                    { label: 'M&R', value: 'M&R' },
                    { label: 'C&S', value: 'C&S' },
                    { label: 'IFP', value: 'IFP' },
                  ],
                },
              ]}
            />

            <DropdownMenu
              outline
              label={`Coverage Type: ${coverageType}`}
              after={<IconSymbol icon="keyboard_arrow_down" />}
              menuItems={[
                {
                  label: `Coverage Type`,
                  model: 'dropdown-menu-radios-coverage-type',
                  value: coverageType,
                  onChange: setCoverageType,
                  radios: [
                    { label: 'M', value: 'M' },
                    { label: 'BH', value: 'BH' },
                    { label: 'D', value: 'D' },
                    { label: 'V', value: 'V' },
                  ],
                },
              ]}
            />

            <DropdownMenu
              outline
              label={`Selected Plan: ${planName}`}
              after={<IconSymbol icon="keyboard_arrow_down" />}
              menuItems={[
                {
                  label: 'Selected Plan',
                  model: 'dropdown-menu-radios-selected-plan',
                  value: planName,
                  onChange: setPlanName,
                  radios: plans[lineOfBusiness].map((plan) => {
                    return {
                      label: plan.name,
                      value: plan.name,
                    };
                  }),
                },
              ]}
            />

            <DropdownMenu
              outline
              label={`Plan Year: ${planYear}`}
              after={<IconSymbol icon="keyboard_arrow_down" />}
              menuItems={[
                {
                  label: 'Plan Year',
                  model: 'dropdown-menu-radios-plan-year',
                  value: planYear,
                  onChange: setPlanYear,
                  radios: [
                    {
                      label: `${currentYear}`,
                      value: currentYear.toString(),
                    },
                    {
                      label: `${currentYear + 1}`,
                      value: (currentYear + 1).toString(),
                    },
                  ],
                },
              ]}
            />

            <Button
              onClick={() =>
                navigate('/browse', {
                  state: buildPortalData(),
                })
              }
            >
              Submit
            </Button>
          </Layout.Stack>
        </FormProvider>
      </Flex>
    </PageBody>
  );
};
