import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';
import mapboxgl from 'mapbox-gl';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import { useConfig } from '../../frontends/ProviderSearch/context/Analytics';
import { useAdobePageTrackEvent } from '../../hooks/adobeHook/useAdobePageTrackEvent';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { FacilityDetails } from '../../models/FacilityDetails';
import { ProviderDetails } from '../../models/ProviderDetails';
import { Directions } from '../../models/RouteDirections';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import {
  getRoute,
  setResultIcon,
  setZoomLevel,
  updateIconSize,
} from '../../utils/map.utils';
import { getProviderLocationWithProviderGroup } from '../../utils/providerDetails.utils';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { hideScrollbar, mediumScreenOnly } from '../ConstantsStyles';
import { MapDisplay } from '../MapDisplay';
import { MapViewSelectLocationCard } from '../MapViewSelectLocationCard';
import { getGeoLocationFromStorage } from '../PSXHeader/SearchBar/utils';
import { handleLinkAndModalTrack } from '../Utils/adobeTrackUtils';
import {
  convertCoverageType,
  convertProviderTypeToAdobeType,
} from '../Utils/adobeTrackUtils/adobeTrackUtils';
import {
  ChooseALocationHeadingWrap,
  ChooseLocationCardsContainer,
  ChooseLocationLabel,
  LocationsWrapColumn,
  MapViewWrap,
  MobileMapViewWrap,
  SidePaneCloseButton,
  ViewMapButton,
} from './MapViewLocationsWrap.style';
import { MobileViewHeadingNavigation } from './MobileViewHeadingNavigation';

type Props = {
  hasMultipleLocations: boolean;
  isMapView?: boolean;
  mobileScreen: boolean;
  providerDetails: ProviderDetails | FacilityDetails;
  updateLocationButton: React.ReactNode | React.ReactFragment;
  handleGoBack: (a: boolean) => void;
  setIsMapView: (isMapView: boolean) => void;
  useStickyButtonRow: boolean;
  setCurrentIndex: (currentIndex: number) => void;
  directionsButtonClicked: boolean;
  handleUpdateLocation: () => void;
  tabTitle?: string;
  mobileRouteView?: boolean;
  setMobileRouteView: (a: boolean) => void;
};
export const MapViewLocationsWrap = ({
  hasMultipleLocations,
  isMapView = false,
  mobileScreen,
  providerDetails,
  updateLocationButton,
  handleGoBack,
  setIsMapView,
  setCurrentIndex,
  useStickyButtonRow,
  directionsButtonClicked,
  handleUpdateLocation,
  tabTitle,
  mobileRouteView = false,
  setMobileRouteView,
}: Props) => {
  const [viewMapEnhancementsFlag] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_LIST_VIEW_MAP_ENHANCEMENTS,
  ]);
  const [showMapCollapse] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.SHOW_MAP_COLLAPSE,
  ]);
  const [mapboxOnpremEnabled] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_ONPREM_MAPBOX,
  ]);

  const [closeSidePanel, setCloseSidePanel] = useState<boolean>(false);
  const [routeEndCoords, setRouteEndCoords] = useState<
    [number | null, number | null]
  >([null, null]);
  const [directions, setDirections] = useState<Directions>({
    userPlaceName: '',
    endPlaceName: '',
    routes: [],
  });

  const { coverageType } = useChipStore(
    useShallow((state: ChipState) => ({
      coverageType: state.coverageType,
    }))
  );
  const mediumScreen = useMediaQuery(mediumScreenOnly);
  const map = React.useRef<mapboxgl.Map | null>(null);
  const { t } = useTranslation();
  const { longitude, latitude } = getGeoLocationFromStorage();
  const [highlightId, setHighlightId] = useSessionStorage<any>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_HIGHLIGHT_ID,
    { providerId: '', from: '' }
  );
  const [selectedId, setSelectedId] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    ''
  );
  const providerLocations = useMemo(
    () =>
      getProviderLocationWithProviderGroup(providerDetails?.providerLocations),
    [providerDetails?.providerLocations]
  );

  const [mapPinCoords] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_COORDS,
    []
  );

  const selectedIdIndex = selectedId
    ? providerLocations?.findIndex(
        (location) => selectedId === location.locationId
      )
    : 0;

  const highlightRefs = React.useRef<any>({});
  const mobileMapViewClassName = mobileScreen ? 'location-card-map-view' : '';
  const tierLocationIndex = providerDetails?.providerLocations?.findIndex(
    (location) => location?.isTieredProvider === true
  );

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: `${tabTitle}:${Constants.PROVIDER_DETAILS.VIEWONMAP}`,
    sitesectionLevel1: Constants.ADOBE_TRACKING.DETAILS_SITESECTION1,
    sitesectionLevel2: `${convertProviderTypeToAdobeType(
      providerDetails.providerType
    )} details`,
    impressionBlock: {
      type: convertProviderTypeToAdobeType(providerDetails.providerType),
      indicator: '',
    },
    providerBlock: {
      type: convertCoverageType(coverageType),
      tier: tierLocationIndex !== -1 ? 'tier 1' : '',
    },
  });

  useEffect(() => {
    if (isMapView) adobePageTrackEvent();
  }, []);

  useEffect(() => {
    highlightRefs.current[highlightId.providerId]?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  }, [highlightId]);

  useEffect(() => {
    map.current?.resize();
    if (!closeSidePanel) {
      setZoomLevel(map.current, mapPinCoords);
    }
  }, [closeSidePanel]);

  useEffect(() => {
    if (mobileScreen) setMobileRouteView(!!routeEndCoords[0]);
  }, [routeEndCoords, mobileScreen]);

  const handleMapButton = () => {
    setIsMapView(true);
  };

  const selectLocation = (index, location) => {
    const { locationId } = location;
    setSelectedId(locationId);
    setCurrentIndex(index);
    const linkName: string = `select location`;
    const linkLocation: string = `body:${Constants.PROVIDER_DETAILS.VIEWONMAP}`;
    const modalName: string = Constants.MAP_VIEW_NAVIGATION_MODAL;
    handleLinkAndModalTrack(linkName, linkLocation, modalName);
  };

  const mapOnPremKey = useConfig('MAPBOX_ONPREM_KEY');
  const mapKey = useConfig('MAPBOX_KEY');
  const mapOnPremURL = useConfig('MAPBOX_ONPREM_URL');

  const handleDirectionClick = async (location) => {
    const { longitude: locationLng, latitude: locationLat } = location;
    const routeDirections = await getRoute(
      map.current,
      longitude,
      latitude,
      +locationLng,
      +locationLat,
      mediumScreen,
      'driving-traffic',
      false,
      0,
      mapboxOnpremEnabled,
      mapOnPremKey,
      mapKey,
      mapOnPremURL
    );
    if (routeDirections) setDirections(routeDirections);
    setRouteEndCoords([locationLng, locationLat]);
    if (mobileScreen) setMobileRouteView(true);
  };

  const handleLocationCardMouseEnter = async (
    index,
    location,
    flyToPin = false
  ) => {
    if (mobileScreen) return;
    await setZoomLevel(map?.current, mapPinCoords);

    if (viewMapEnhancementsFlag) {
      const marker = document.querySelector(
        `#custom-marker-${location.locationId}`
      );
      updateIconSize(true, marker, true);
    }
    setResultIcon(
      map.current,
      index,
      true,
      setHighlightId,
      location.locationId
    );

    if (flyToPin) {
      map.current?.flyTo({
        center: [+location.longitude, +location.latitude],
        zoom: Constants.DEVICE_LOCATION.ZOOM,
      });
    }
  };

  const handleLocationCardMouseLeave = (index, location) => {
    if (viewMapEnhancementsFlag) {
      const marker = document.querySelector(
        `#custom-marker-${location.locationId}`
      );
      updateIconSize(false, marker, true);
    }
    if (!selectedId) {
      setResultIcon(map.current, index, false, setHighlightId, null);
    }
  };

  const displayRoute = async (locationLng, locationLat, openDirections) => {
    const routeDirections = await getRoute(
      map.current,
      longitude,
      latitude,
      +locationLng,
      +locationLat,
      false,
      'driving-traffic',
      openDirections,
      0,
      mapboxOnpremEnabled,
      mapOnPremKey,
      mapKey,
      mapOnPremURL
    );
    if (routeDirections) setDirections(routeDirections);
  };

  useEffect(() => {
    if (directionsButtonClicked && map.current) {
      const {
        locationId,
        longitude: locationLng,
        latitude: locationLat,
      } = providerLocations[selectedIdIndex];
      setRouteEndCoords([Number(locationLng), Number(locationLat)]);
      setSelectedId(locationId);
      map.current?.on('load', () => {
        displayRoute(locationLng, locationLat, directionsButtonClicked);
      });
    }
  }, [map.current]);

  const chooseALocationHeading =
    hasMultipleLocations && !isMapView && !mobileScreen ? (
      <ChooseALocationHeadingWrap
        data-auto-testid="top-locations-label-and-back-navigation"
        data-testid="top-locations-label-and-back-navigation"
      >
        <ChooseLocationLabel color="$primary1" fontWeight="$bold">
          {t('PROVIDER_DETAILS.LOCATIONS')}
          {` (${providerLocations.length})`}
        </ChooseLocationLabel>
        <ViewMapButton
          before={
            <IconMaterial
              color="$interactive1"
              icon="map"
              isScreenReadable
              size="15px"
            />
          }
          data-auto-testid="map-button"
          data-testid="map-button"
          onClick={handleMapButton}
          variant="ghost"
        >
          {t('View Map')}
        </ViewMapButton>
      </ChooseALocationHeadingWrap>
    ) : null;

  return (
    <MobileMapViewWrap
      className={mobileScreen ? 'show-mobile-map-view' : ''}
      data-testid="map-view-wrap-locations"
      style={{ paddingRight: viewMapEnhancementsFlag ? '0' : '' }}
    >
      <LocationsWrapColumn
        className="locations-wrap-column"
        css={hideScrollbar}
      >
        {!closeSidePanel && (
          <div>
            {!mobileScreen ? (
              <Link
                data-auto-testid="back-to-locations-details"
                data-testid="back-to-locations-details"
                href="#"
                isStandardAnchor
                onClick={handleGoBack}
              >
                <IconMaterial
                  color="$interactive1"
                  css={{ width: '24px', height: '24px', marginRight: '4px' }}
                  icon="arrow_back"
                />
                <Text color="$interactive1" fontWeight="$bold">
                  {t('BACK')}
                </Text>
              </Link>
            ) : null}
            {chooseALocationHeading}
            <ChooseLocationCardsContainer
              alignItems="flex-end"
              aria-label={t('ACCESSIBILITY_LABELS.LOCATIONS')}
              className="choose-location-container"
              role="group"
            >
              {!mobileRouteView
                ? providerLocations?.map((location, index) => (
                    <div
                      aria-pressed={selectedId === location.locationId}
                      className="location-cards-container"
                      data-auto-testid="location-section"
                      data-testid="location-section"
                      key={`details-location-${index + 1}`}
                      onBlur={() =>
                        handleLocationCardMouseLeave(index, location)
                      }
                      onFocus={() =>
                        handleLocationCardMouseEnter(index, location)
                      }
                      onMouseEnter={() =>
                        handleLocationCardMouseEnter(index, location)
                      }
                      onMouseLeave={() =>
                        handleLocationCardMouseLeave(index, location)
                      }
                    >
                      <MapViewSelectLocationCard
                        className={`
                      ${mobileMapViewClassName} ${
                          highlightId.providerId === location.locationId
                            ? 'highlight'
                            : ''
                        }
                    `}
                        handleDirectionClick={() =>
                          handleDirectionClick(location)
                        }
                        handleUpdateLocation={handleUpdateLocation}
                        highlightRefs={highlightRefs}
                        location={location}
                        selectLocation={() => selectLocation(index, location)}
                        selected={selectedId === location.locationId}
                        setIsMapView={setIsMapView}
                        showBadgeRow
                        showRadioSelect={hasMultipleLocations}
                      />
                    </div>
                  ))
                : null}
            </ChooseLocationCardsContainer>
            {useStickyButtonRow ? updateLocationButton : null}
          </div>
        )}
        {showMapCollapse ? (
          <SidePaneCloseButton
            alignItems="center"
            className="side-btn"
            justify="center"
          >
            <IconMaterial
              css={{
                fill: '$black',
              }}
              data-auto-testid="sidepanel-close-icon"
              data-testid="sidepanel-close-icon"
              icon={
                closeSidePanel ? 'keyboard_arrow_right' : 'keyboard_arrow_left'
              }
              onClick={() => {
                setCloseSidePanel(!closeSidePanel);
                adobeLinkTrackEvent({
                  name: `sidepanel:${closeSidePanel ? 'open' : 'close'}`,
                  location: 'body:map',
                  type: 'internal',
                });
              }}
              size="$md"
            />
          </SidePaneCloseButton>
        ) : null}
      </LocationsWrapColumn>
      {!mobileRouteView ? (
        <MobileViewHeadingNavigation
          handleGoBack={handleGoBack}
          mobileScreen={mobileScreen}
          providerDetails={providerDetails}
        />
      ) : null}
      <MapViewWrap
        cssProps={{ viewMapEnhancementsFlag, closeSidePanel, mobileRouteView }}
      >
        {providerLocations?.length ? (
          <MapDisplay
            coords={{ longitude, latitude }}
            directions={directions}
            fullWidth={viewMapEnhancementsFlag}
            isLocationsTab
            locationResults={providerLocations}
            map={map}
            mobileRouteView={mobileRouteView}
            mobileScreen={mobileScreen}
            refToBeFocusedOnDirectionsClose={
              highlightRefs.current[highlightId.providerId] as HTMLElement
            }
            routeEndCoords={routeEndCoords}
            setRouteEndCoords={setRouteEndCoords}
          />
        ) : null}
      </MapViewWrap>
    </MobileMapViewWrap>
  );
};
