import { config } from '@abyss/web/tools/config';
import { useEffect } from 'react';

import { getLanguage } from '../../frontends/ProviderSearch/context/Internationalization/helpers';
import { useCustomQuery } from '../useCustomQuery';
import GET_FACILITIES_QUERY from './Facilities.graphql';

export const useFacilities = (options) =>
  useCustomQuery(GET_FACILITIES_QUERY, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'Facilities',
  });

export const useFacilitiesResult = (variables) => {
  const [facilitiesResult, getFacilities] = useFacilities({});
  const { coverageType } = variables;
  useEffect(() => {
    const locale = getLanguage()?.code;
    getFacilities({
      variables: {
        locale,
        coverageType: coverageType ?? 'M',
      },
    });
  }, [coverageType]);

  return { data: facilitiesResult.data?.facilities };
};
