import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { styled } from '@abyss/web/tools/styled';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import { PortalContext } from '../../context/PortalContext';
import { useCoverageType } from '../../hooks/useCoverageType';
import { useCurrentMemberIsPreEffective } from '../../hooks/useCurrentMemberIsPreEffective';
import { useLagoon } from '../../hooks/useLagoon';
import { ProviderLocation, ProviderType } from '../../models/ProviderDetails';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import {
  returnInclusionFlag,
  returnSuppressFlag,
} from '../../utils/featureSuppress';
import {
  getFormattedPhoneNumber,
  getPrimaryPhone,
} from '../../utils/phone.utils';
import { getSelectedLocationIndex } from '../../utils/providerDetails.utils';
import { parseProviderName } from '../../utils/providerNames.utils';
import {
  getCurrentMember,
  getIsCurrentMemberSubscriber,
  isLoggedInMember,
} from '../../utils/user.utils';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { adobeModalTrackEvent } from '../AdobeTagging/adobeModalTrackEvent';
import {
  CareCategories,
  Constants,
  LOB,
  ReverseCoverageTypesCodes,
} from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { phoneOnly } from '../ConstantsStyles';
import { CallModal } from '../DataCard/CallModal';
import { ResponsiveText } from '../DataCard/DataCard.style';
import { phoneTextStyles } from '../DataCard/Phone';
import { ScheduleModal } from '../ScheduleModal';
import { getFeatureFlag } from '../Utils';
import { InteractButton } from './DetailsPageHeader.styled';
import { DetailsPageSchedulePopover } from './DetailsPageSchedulePopover';

const PhoneTag = styled('a', {
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  '&:hover,&:active': {
    textDecoration: 'none',
  },
});

export type Props = {
  chip: string;
  npi: string;
  practiceId: string;
  providerName: string;
  providerLocations: ProviderLocation[];
  professionalDesignation?: string;
  providerType: ProviderType;
  nameAttrs?: any;
  selectedLocation: ProviderLocation;
  category: string;
  providerId: string;
  locationId: string;
  sectionType: string;
  isTieredDataCard: boolean;
  isLimitedAccessToProvider: boolean;
};

const getIsMemberEligibleToSchedule = (
  isGuestUser: any,
  checkShowScheduleBtn: boolean
) => isGuestUser || checkShowScheduleBtn;
const getIsShowCallChip = (primaryPhoneNumber: any, category: string) =>
  primaryPhoneNumber &&
  category !== CareCategories.DENTAL &&
  category !== CareCategories.VISION;
const getCheckShowScheduleBtn = (
  showScheduleButton: boolean,
  isMemberEligibleToSchedule: boolean,
  dentalOrVisionCoverage: boolean,
  providerType: ProviderType,
  suppressDocAsap: boolean,
  includeDocAsap: boolean,
  lineOfBusiness: string
) =>
  showScheduleButton &&
  isMemberEligibleToSchedule &&
  !dentalOrVisionCoverage &&
  providerType === ProviderType.PRACTITIONER &&
  lineOfBusiness === LOB.CNS
    ? includeDocAsap
    : !suppressDocAsap;

export const DetailsPageChips = ({
  chip,
  npi,
  practiceId,
  providerName,
  providerLocations,
  nameAttrs,
  professionalDesignation,
  providerType,
  category,
  providerId,
  sectionType,
  isTieredDataCard,
  isLimitedAccessToProvider = false,
}: Props) => {
  const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const coverageType = useCoverageType();
  const featureFlags = useLagoon('feature-flags')();
  const showScheduleButton = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_SCHEDULE_OPTION
  );
  const schedulingEnabled = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ONLINE_SCHEDULING
  );
  const isCTADisplayLogic = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.PSX_CTA_DISPLAY_LOGIC
  );
  const [selectedId] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    ''
  );
  const { navigate } = useRouter();
  const { portalData } = useContext(PortalContext);

  const getRedirectionUrl = (providerId: string, handleScheduleButtonClick) =>
    !providerId
      ? navigate(`${portalData?.portalConfig?.homePage}`)
      : { handleScheduleButtonClick };

  const typeaheadState = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      initiateDocasapSession: state.typeaheadSearchStore.initiateDocasapSession,
    }))
  );

  useEffect(() => {
    if (isOpenScheduleModal && !isCurrentMemberPreEffective) {
      // adobeModalTrackEvent('schedule a visit:open');
      adobeLinkTrackEvent({
        name: Constants.DETAILS_CHIP_NAMES.Schedule,
        location: 'body:details header',
        type: 'internal',
      });
      adobeModalTrackEvent(
        `schedule a visit:${docAsapEnabled ? 'schedule' : 'call'}`
      );
    }
  }, [isOpenScheduleModal]);

  useEffect(() => {
    if (typeaheadState?.initiateDocasapSession) {
      handleScheduleButtonClick();
    }
  }, [typeaheadState?.initiateDocasapSession]);

  const index = getSelectedLocationIndex(providerLocations, selectedId);

  const phone = providerLocations?.[index]?.phones?.phone;
  const locationId = providerLocations?.[index]?.locationKey;
  const appointment = providerLocations?.[index]?.phones?.appointment;
  const primaryPhoneNumber = getPrimaryPhone(phone, appointment);
  const website = providerLocations?.[index]?.website;
  const email = providerLocations?.[index]?.emailAddress;
  const showScheduleButtonForDpndt: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_SCHEDULING_FOR_DEPENDENT
  );

  const isGuestUser = storage.session.get(
    Constants.STORAGE_KEYS.SESSION.IS_GUEST
  );

  const isMemberEligibleToSchedule = showScheduleButtonForDpndt
    ? isLoggedInMember()
    : getIsCurrentMemberSubscriber();

  const docAsapEnabled = schedulingEnabled && practiceId;

  const handleScheduleButtonClick = () => {
    if (!isCurrentMemberPreEffective) {
      setIsOpenScheduleModal(true);
    }
  };

  if (typeaheadState?.initiateDocasapSession) {
    getRedirectionUrl(providerId, { handleScheduleButtonClick });
  }

  const dentalOrVisionCoverage = [
    ReverseCoverageTypesCodes.DENTAL,
    ReverseCoverageTypesCodes.VISION,
  ].includes(coverageType as ReverseCoverageTypesCodes);

  const { lineOfBusiness } = getCurrentMember();

  const suppressDocAsap = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.SUPPRESS_DOC_ASAP
  );
  const includeDocAsap =
    lineOfBusiness === LOB.CNS &&
    returnInclusionFlag(
      ConstantsLagoon.FEATURE_INCLUSION_FLAGS.INCLUDE_DOC_ASAP
    );

  const isCurrentMemberPreEffective = useCurrentMemberIsPreEffective();
  const checkShowScheduleBtn = getCheckShowScheduleBtn(
    showScheduleButton,
    isMemberEligibleToSchedule,
    dentalOrVisionCoverage,
    providerType,
    suppressDocAsap,
    includeDocAsap,
    lineOfBusiness
  );

  const showScheduleChip = getIsMemberEligibleToSchedule(
    isGuestUser,
    checkShowScheduleBtn
  );

  const showCallChip = getIsShowCallChip(primaryPhoneNumber, category);

  if (
    (chip === Constants.DETAILS_CHIP_NAMES.Schedule && !showScheduleChip) ||
    (chip === Constants.DETAILS_CHIP_NAMES.Call && !showCallChip) ||
    (chip === Constants.DETAILS_CHIP_NAMES.Email && !email) ||
    (chip === Constants.DETAILS_CHIP_NAMES.Website && !website)
  ) {
    return null;
  }

  const phonePrimaryTestId = 'phone-primary-detailes-header';

  const CallButton = () => (
    <InteractButton
      before={
        <IconMaterial
          color="$primary1"
          icon={isCTADisplayLogic ? 'call' : 'call_end'}
        />
      }
      data-auto-testid={phonePrimaryTestId}
      data-testid={phonePrimaryTestId}
      href={
        !isCTADisplayLogic
          ? `tel:${getFormattedPhoneNumber(primaryPhoneNumber)}`
          : '#'
      }
      key={`provider-details-chips-${chip}`}
      onClick={() =>
        !isCTADisplayLogic
          ? adobeLinkTrackEvent({
              name: phonePrimaryTestId,
              location: 'body:details header',
              type: 'phone',
            })
          : setIsOpen(true)
      }
      variant="outline"
    >
      {t('DETAILS_CHIP_NAMES.Call')}
    </InteractButton>
  );

  switch (chip) {
    case Constants.DETAILS_CHIP_NAMES.Schedule:
      return (
        <React.Fragment>
          {!isCurrentMemberPreEffective &&
            isCTADisplayLogic &&
            docAsapEnabled &&
            !isLimitedAccessToProvider && (
              <InteractButton
                before={
                  <IconMaterial
                    color="$primary1"
                    icon="perm_contact_calendar"
                  />
                }
                data-auto-testid="provider-details-chips-schedule"
                data-testid="provider-details-chips-schedule"
                key={`provider-details-chips-${chip}`}
                onClick={handleScheduleButtonClick}
                variant="outline"
              >
                {t('DETAILS_CHIP_NAMES.Schedule_Online')}
              </InteractButton>
            )}
          {!isCurrentMemberPreEffective && !isCTADisplayLogic && (
            <InteractButton
              before={<IconMaterial color="$primary1" icon="date_range" />}
              data-auto-testid="provider-details-chips-schedule"
              data-testid="provider-details-chips-schedule"
              key={`provider-details-chips-${chip}`}
              onClick={handleScheduleButtonClick}
              variant="outline"
            >
              {t('DETAILS_CHIP_NAMES.Schedule')}
            </InteractButton>
          )}
          {isCurrentMemberPreEffective && docAsapEnabled && (
            <DetailsPageSchedulePopover />
          )}
          <ScheduleModal
            isOpen={isOpenScheduleModal}
            isTieredDataCard={isTieredDataCard}
            locationId={locationId}
            npi={npi}
            phone={primaryPhoneNumber}
            practiceId={practiceId}
            providerId={providerId}
            providerName={parseProviderName(
              providerName,
              providerType,
              professionalDesignation,
              nameAttrs
            )}
            sectionType={sectionType}
            setIsOpen={setIsOpenScheduleModal}
          />
        </React.Fragment>
      );

    case Constants.DETAILS_CHIP_NAMES.Call:
      return (
        <>
          <CallButton />
          {isCTADisplayLogic && isOpen && (
            <CallModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              title="Provider Detail Call Modal"
            >
              <PhoneTag
                data-auto-testid={phonePrimaryTestId}
                data-testid={phonePrimaryTestId}
                href={`tel:${getFormattedPhoneNumber(primaryPhoneNumber)}`}
                onClick={(e: SyntheticEvent) => e.stopPropagation()}
              >
                <IconMaterial
                  data-auto-testid={`data-card-phone-icon-${phonePrimaryTestId}-${practiceId}`}
                  data-testid={`data-card-phone-icon-${phonePrimaryTestId}-${practiceId}`}
                  icon="phone"
                  size={mobileScreen ? 18 : 24}
                />
                <ResponsiveText
                  color="$interactive1"
                  css={phoneTextStyles}
                  data-auto-testid={`data-card-phone-number-${phonePrimaryTestId}-${practiceId}`}
                  data-testid={`data-card-phone-number-${phonePrimaryTestId}-${practiceId}`}
                  fontWeight="$bold"
                  onClick={() =>
                    adobeLinkTrackEvent({
                      name: phonePrimaryTestId,
                      location: 'body:details header',
                      type: 'phone',
                    })
                  }
                >
                  {t(`${getFormattedPhoneNumber(primaryPhoneNumber)}`)}
                </ResponsiveText>
              </PhoneTag>
            </CallModal>
          )}
        </>
      );

    case Constants.DETAILS_CHIP_NAMES.Email:
      return (
        <InteractButton
          before={<IconMaterial color="$primary1" icon="email" />}
          color="$primary1"
          data-auto-testid={`${email}-email-address`}
          data-testid={`${email}-email-address`}
          fontWeight="$semibold"
          href={`mailto:${email}`}
          key={`provider-details-chips-${chip}`}
          onClick={() =>
            adobeLinkTrackEvent({
              name: 'email chip',
              location: 'body:details header',
              type: 'email',
              destinationUrl: `mailto:${email}`,
            })
          }
          variant="outline"
        >
          {t('DETAILS_CHIP_NAMES.Email')}
        </InteractButton>
      );

    case Constants.DETAILS_CHIP_NAMES.Website:
      return (
        <InteractButton
          before={<IconMaterial color="$primary1" icon="web" />}
          color="$primary1"
          data-auto-testid={`provider-website${website}`}
          data-testid={`provider-website${website}`}
          fontWeight="$semibold"
          href={website}
          key={`provider-details-chips-${chip}`}
          onClick={() =>
            adobeLinkTrackEvent({
              name: 'website chip',
              location: 'body:details header',
              type: 'external',
              destinationUrl: website,
            })
          }
          variant="outline"
        >
          {t('DETAILS_CHIP_NAMES.Website')}
        </InteractButton>
      );

    default:
      return null;
  }
};
