import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DownloadResultsButton } from '../../frontends/ProviderSearch/routes/ViewAll/DownloadResultsButton';
import { MapButton } from '../../frontends/ProviderSearch/routes/ViewAll/MapButton';
import { ShareCompareButton } from '../../frontends/ProviderSearch/routes/ViewAll/ShareCompareButton';
import { CompareProvider } from '../../models/Provider';
import { Constants } from '../Constants';
import { ChipFiltersContainer, Wrapper } from './FilterButtons.styled';
import { ResultFilter } from './ResultFilter';
import { ResultSort } from './ResultSort';

type Props = {
  setOpenCompare: (a: boolean) => void;
  setOpenMapview: (a: boolean) => void;
  setOpenShare: (a: boolean) => void;
  setSelectedItems: (a: CompareProvider[]) => void;
  setSelectedCheckbox: (a: { checked: {} }) => void;
  setOpenBoxContents: (a: boolean) => void;
  openShare: boolean;
  openCompare: boolean;
  sectionType: string;
  enableMapViewEnhancements: boolean;
  results: any[];
};

export const FilterButtons = ({
  setSelectedCheckbox,
  setSelectedItems,
  setOpenCompare,
  setOpenMapview,
  setOpenShare,
  openShare,
  openCompare,
  sectionType,
  setOpenBoxContents,
  enableMapViewEnhancements,
  results,
}: Props) => {
  const { t } = useTranslation();
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<boolean>(false);

  const toggleShowAll = () => setShowAll(!showAll);

  const ActionButton = (
    <Button
      css={{
        'abyss-button-root': {
          paddingRight: '0px',
          paddingLeft: '0px',
        },
      }}
      data-testid={`action-${showAll ? 'show-less' : 'show-all'}`}
      onClick={toggleShowAll}
      size="$sm"
      variant="tertiary"
    >
      <Text
        color="$interactive1"
        css={{
          lineHeight: '18px',
        }}
        fontWeight="$normal"
        size="$sm"
      >
        {t(`CONTENT_FILTERS.${showAll ? 'SHOW_LESS' : 'SHOW_ALL'}`)}
      </Text>
      <IconMaterial
        icon={showAll ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
        size="18px"
      />
    </Button>
  );
  return (
    <div
      style={{
        paddingTop: '16px',
        paddingBottom: enableMapViewEnhancements ? '8px' : '12px',
        paddingLeft: enableMapViewEnhancements ? '24px' : '0',
      }}
    >
      <Wrapper direction="row">
        <ChipFiltersContainer
          className="hide-scrollbar"
          data-testid="button-list"
          showAll={showAll}
        >
          <MapButton
            isDisabled={openShare}
            openCompare={openCompare}
            setOpenMapview={setOpenMapview}
          />
          <ResultFilter name={t('CONTENT_FILTERS.FILTER')} size="$sm" />
          {!enableMapViewEnhancements ? (
            <React.Fragment>
              {sectionType === Constants.RESULT_SECTION.PROVIDER && (
                <ShareCompareButton
                  isShareButton={false}
                  openCompare={openCompare}
                  sectionType={sectionType}
                  setIsClicked={setIsClicked}
                  setOpenCompare={setOpenCompare}
                  setOpenShare={setOpenShare}
                  setSelectedCheckbox={setSelectedCheckbox}
                  setSelectedItems={setSelectedItems}
                  text={t('COMPARE_BUTTONS.COMPARE')}
                  view="mobile"
                />
              )}
              <ShareCompareButton
                openShare={openShare}
                sectionType={sectionType}
                setIsClicked={setIsClicked}
                setOpenCompare={setOpenCompare}
                setOpenShare={setOpenShare}
                setSelectedCheckbox={setSelectedCheckbox}
                setSelectedItems={setSelectedItems}
                text={t('COMPARE_BUTTONS.SHARE')}
                view="mobile"
              />
              <DownloadResultsButton
                isClicked={isClicked}
                results={results}
                setIsClicked={setIsClicked}
                setOpenBoxContents={setOpenBoxContents}
                setOpenCompare={setOpenCompare}
                setOpenShare={setOpenShare}
                setSelectedCheckbox={setSelectedCheckbox}
                setSelectedItems={setSelectedItems}
                text={t('COMPARE_BUTTONS.DOWNLOAD_RESULTS')}
                view="mobile"
              />
              <ResultSort sectionType={sectionType} />
              {showAll && ActionButton}
            </React.Fragment>
          ) : (
            <ResultSort sectionType={sectionType} />
          )}
        </ChipFiltersContainer>
      </Wrapper>
      {!showAll && !enableMapViewEnhancements && (
        <Flex justify="end">{ActionButton}</Flex>
      )}
    </div>
  );
};
