import React from 'react';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { SearchFilterContext } from '../../context/SearchFilterContext';
import { useParsedProviderFilters } from '../../hooks/useProviderFilterCount';
import { useProviderFilters } from '../../hooks/useProviderFilters';
import { StoreKeys } from '../../hooks/useStore/state';
import { useStore } from '../../hooks/useStore/useStore';
import {
  LastModifiedFilter,
  ProviderSearchFilters,
} from '../../models/ProviderSearch';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { transformFiltersData } from '../../utils/providerSearch.utils';
import { FilterCollectionModel } from '../Constants';
import { NameFilter } from '../ContentFilters/NameFilter';
import { getGeoLocationFromStorage } from '../PSXHeader/SearchBar/utils';

type Props = {
  sectionType: string;
  previousLocation: string;
  setPreviousLocation: (a: string) => void;
};

export const ChipFilters = ({
  sectionType = '',
  previousLocation,
  setPreviousLocation,
}: Props) => {
  const { t } = useTranslation();
  const filtersCollection = useProviderFilters();

  const { coverageType } = useChipStore(
    useShallow((state: ChipState) => ({
      coverageType: state.coverageType,
    }))
  );

  const { searchFilters, setSearchFilters, defaultSearchFilters } =
    useContext(SearchFilterContext);
  const [selectedFilters, setSelectedFilters] =
    useState<ProviderSearchFilters>(searchFilters);
  const [lastModifiedFilter, setLastModifiedFilter] =
    useState<LastModifiedFilter>({});

  const { name } = getGeoLocationFromStorage();

  const {
    totalCount = 0,
    providerFilterList,
    isLoading,
  } = useParsedProviderFilters({
    selectedFilters:
      previousLocation !== name ? defaultSearchFilters : selectedFilters,
  });

  const filters = transformFiltersData(
    t,
    filtersCollection,
    providerFilterList,
    lastModifiedFilter,
    selectedFilters
  );

  const setResultsHasProviderWithUpcomingAvailability = useStore(
    StoreKeys.SET_RESULTS_HAS_PROVIDER_WITH_UPCOMING_AVAILABILITY
  );

  const resetFiltersState = () => {
    if (previousLocation !== name) {
      setSearchFilters({ ...defaultSearchFilters });
      setSelectedFilters({ ...defaultSearchFilters });
      setPreviousLocation(name);
    } else if (
      JSON.stringify(selectedFilters) !== JSON.stringify(searchFilters)
    ) {
      setSelectedFilters({ ...searchFilters });
    }
  };

  const setUpcomingAvailabilty = (filters) => {
    const hasUpcomingAvailability =
      filters?.UpcomingAvailability?.options?.length > 0;
    setResultsHasProviderWithUpcomingAvailability(hasUpcomingAvailability);
  };

  useEffect(() => {
    setUpcomingAvailabilty(providerFilterList);
  }, [JSON.stringify(providerFilterList)]);

  useEffect(() => {
    resetFiltersState();
  }, [JSON.stringify(searchFilters), name]);

  const onApplyFilter = (data) => {
    setLastModifiedFilter({});
    setSearchFilters({
      ...searchFilters,
      ...data,
    });
  };

  const onFilterSelectionChange = (
    data: ProviderSearchFilters,
    lastModifiedModel: string = ''
  ) => {
    setLastModifiedFilter(
      filters.find((filter) => filter.model === lastModifiedModel) || {}
    );
    setSelectedFilters({
      ...searchFilters,
      ...data,
    });
  };

  const onPopoverDismiss = () => {
    resetFiltersState();
  };

  const clearFilter = (model: string) => {
    const filteredData = defaultSearchFilters[model];
    if (filteredData) {
      setSearchFilters({ ...searchFilters, [model]: filteredData });
    } else {
      const updatedSearchFilters = { ...searchFilters };
      delete updatedSearchFilters[model];
      setSearchFilters(updatedSearchFilters);
    }
  };

  const filteredFilters = filters
    .filter(({ model }) => model !== FilterCollectionModel.CULTURAL_COMPETENCE)
    .filter(({ chipFilters }) =>
      chipFilters[coverageType]?.includes(sectionType)
    );

  return (
    <Fragment>
      {filteredFilters
        .sort(
          (a, b) =>
            a.chipFiltersOrder[coverageType] - b.chipFiltersOrder[coverageType]
        )
        .map(({ label, title, options, model, type }) => (
          <NameFilter
            clearFilter={clearFilter}
            defaultValue={selectedFilters[model]}
            isLoading={isLoading}
            key={label}
            label={label}
            model={model}
            onApplyFilter={onApplyFilter}
            onFilterSelectionChange={onFilterSelectionChange}
            onPopoverDismiss={onPopoverDismiss}
            options={options}
            title={title || label}
            totalCount={totalCount}
            type={type}
          />
        ))}
    </Fragment>
  );
};
