import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';

export const CarePathResultsList = styled('ul', {
  listStyleType: 'none',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  whiteSpace: 'wrap',
  marginBottom: '24px',
});

export const CarePathResultContainer = styled(Flex, {
  '&.abyss-flex-root': {
    alignItems: 'center',
    height: 'auto',
    display: 'flex',
    alignSelf: 'stretch',
    gap: '24px !important',
    '@screen < $lg': {
      gap: '8px !important',
      height: 'auto',
    },
    '@screen < $md': {
      gap: '8px !important',
      height: 'auto',
    },
    '@screen > $md': {
      gap: '8px !important',
      height: 'auto',
    },
  },
});

export const CarePathResultName = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '24px',
    width: '30%',
    textWrap: 'wrap',
    color: '#4B4D4F',
    '@media (max-width: 1248px)': {
      width: '250px',
    },
    '@media (max-width: 765px)': {
      width: '100%',
    },
    '@screen < $md': {
      width: '100%',
    },
    '@screen < $sm': {
      width: '343px',
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
});

export const CarePathCostContainer = styled(Flex, {
  '&.abyss-flex-root': {
    gap: '24px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    '@media (max-width: 1248px)': {
      gap: '8px !important',
      flexDirection: 'column',
      width: 'auto',
      alignItems: 'flex-start',
    },
    '@screen < $md': {
      width: '50%',
    },
    '@screen < $sm': {
      width: '100%',
    },
  },
});

export const CarePathCostText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '24px',
    width: '229px',
    color: '#4B4D4F',
    '@screen < $lg': {
      width: '229px',
    },
    '@screen < $sm': {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '20px',
    },
  },
});
export const CarePathCost = styled(Text, {
  fontSize: '20.25px',
  fontWeight: 700,
  lineHeight: '24px',
  color: '#323334',
  '@screen < $sm': {
    fontSize: '18px',
  },
});

export const CarePathEstimationButtonStyles = {
  '&.abyss-button-root': {
    display: 'flex',
    height: '42px',
    padding: '8px 20px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    borderRadius: '24px',
    border: '2px solid #002677',
    fonSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
    '@screen < $sm': {
      marginTop: '8px',
    },
  },
};

export const CostIndicatorStyled = {
  '&.abyss-flex-root': {
    display: 'flex',
    flexDirection: 'row',
    width: '230px',
    alignItems: 'center',
    '@screen < $sm': {
      width: 'auto',
      gap: '2px',
    },
    '@screen < $lg': {
      width: '230px',
      gap: '4px',
    },
    '@screen < $md': {
      width: '230px',
      gap: '4px',
    },
  },

  '.abyss-icon-material': {
    marginTop: '8px',
    '@screen < $sm': {
      marginTop: '2px',
    },
  },
  '.abyss-text-root': {
    fontSize: '18px !important',
    '@screen < $sm': {
      fontSize: '16px !important',
    },
  },
};

export const CostIndicatorText = styled(Text, {
  marginLeft: '2px',
});

export const CarePathWebDropdownStyles = {
  'abyss-dropdown-menu-content': {
    padding: '$md 12px',
    border: '1px solid #D0D0D0',
    borderRadius: '5px',
    width: '439px',
  },
  'abyss-dropdown-menu-item': {
    height: '60px',
    marginLeft: '12px',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '24px',
    '&:hover:focus, &:focus': {
      backgroundColor: 'white',
    },
  },
  'abyss-dropdown-menu-trigger': {
    padding: '8px 16px',
    borderRadius: '4px',
    border: '1px solid #CBCCCD',
    background: '#FFF',
    width: '407px',
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '24px',
    color: '#323334',
    marginTop: '24px',
  },
  'abyss-insert-element-root': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center !important',
    color: '#323334',
  },
  '&.abyss-icon': {
    fill: '#323334 !important',
  },
};

export const carePathFlexStyles = {
  '&.abyss-flex-root': {
    display: 'flex',
    width: '407px',
    height: '58px',
    padding: '16px',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '12px',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    flexWrap: 'nowrap',
    borderRadius: '4px',
    border: '1px solid #CBCCCD',
    background: '#FFF',
    marginTop: '12px',
    '@screen < $sm': {
      width: '100%',
      marginBottom: '24px',
      height: '56px',
    },
  },
};

export const carePathTextStyles = {
  'abyss-text-root': {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '24px',
    color: '#323334',
    '@screen < $sm': {
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
};

export const carePathButtonStyles = {
  'abyss-button-root': {
    '@screen < $sm': {
      display: 'flex',
      height: '42px',
      padding: '8px 20px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '4px',
      borderRadius: '24px',
      border: '2px solid #002677',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '20px',
      alignSelf: 'stretch',
    },
  },
};

export const CarePathFlexContainer = styled(Flex, {
  '&.abyss-flex-root': {
    display: 'flex',
    width: '100% !important',
    flexDirection: 'column !important',
    alignItems: 'flex-start',
    gap: '24px',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    overflow: 'hidden',
    '@screen < $sm': {
      gap: '12px',
    },
  },
});

export const carePathResponsiveDrawerStyles = {
  'abyss-modal-content-container': {
    borderRadius: '20px 20px 0px 0px',
    padding: '$md',
    height: 'auto !important',
  },
  'abyss-modal-close-button': {
    top: '12px',
    right: '12px',
  },
  'abyss-modal-header-container': {
    padding: '0',
    marginBottom: '$md',
  },
  'abyss-modal-content-title': {
    fontFamily: '$primary !important',
    fontSize: '$mediumText !important',
    fontWeight: '$bold !important',
    lineHeight: '24px !important',
    color: '$gray-07 !important',
    padding: '0',
  },
};

export const carePathPopoverStyles = {
  'abyss-popover-content': {
    padding: '24px',
    borderRadius: '12px ',
  },
  'abyss-popover-header': {
    paddingBottom: '8px',
  },
  'abyss-popover-close-button': {
    top: '26px',
    right: '24px',
  },
  'abyss-popover-close-icon': {
    width: '24px',
    height: '24px',
  },
  'abyss-popover-title': {
    color: '#323334 !important',
    fontSize: '25.63px !important',
    fontWeight: '700 !important',
    lineHeight: '32px !important',
  },
};
