import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { storage } from '@abyss/web/tools/storage';
import React, { useEffect, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import { adobeLinkTrackEvent } from '../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { adobeStandardSearchEvent } from '../../../../common/AdobeTagging/adobeStandardSearchEvent';
import { CardWrapperDetailsPage } from '../../../../common/CardWrapper';
import {
  AdobeEventMethods,
  Constants,
  ProviderTypes,
} from '../../../../common/Constants';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { ConstantsRoutes } from '../../../../common/ConstantsRoutes';
import { phoneOnly } from '../../../../common/ConstantsStyles';
import { DetailsPageHeader } from '../../../../common/DetailsPageHeader/DetailsPageHeader';
import { LoadingSpinnerComp } from '../../../../common/Loader';
import { ProviderDetailTabs } from '../../../../common/ProviderDetailTabs/ProviderDetailTabs';
import { getGeoLocationFromStorage } from '../../../../common/PSXHeader/SearchBar/utils';
import { SendFeedback } from '../../../../common/SendFeedback/SendFeedback';
import { getFeatureFlag } from '../../../../common/Utils';
import {
  getSearchLocation,
  internalConvertProviderTypeToAdobeType,
} from '../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { useInitializeStore } from '../../../../hooks/useInitializeStore';
import { useLagoon } from '../../../../hooks/useLagoon';
import { useProviderDetails } from '../../../../hooks/useProviderDetails';
import { ProviderType } from '../../../../models/ProviderDetails';
import { useAnalyticsStore } from '../../../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../../../store/useAnalyticsStore/analyticsStore';
import { useChipStore } from '../../../../store/useChipStore';
import { ChipState } from '../../../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../../../store/useDetailsStore';
import { DetailsStore } from '../../../../store/useDetailsStore/detailsStore';
import { useTypeaheadStore } from '../../../../store/useTypeaheadStore';
import { TypeaheadState } from '../../../../store/useTypeaheadStore/typeaheadStore';
import {
  getNetworkStatus,
  getSelectedLocationIndex,
} from '../../../../utils/providerDetails.utils';

const LOCATIONS_TAB_INDEX = 1;

export const GroupDetails = () => {
  const mobileScreen = useMediaQuery(phoneOnly);

  const { coverageType, chipValue } = useChipStore(
    useShallow((state: ChipState) => ({
      coverageType: state.coverageType,
      chipValue: state.chipValue,
    }))
  );

  const { searchTerm, searchFromTypeAhead } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      searchTerm: state.typeaheadSearchStore.searchTerm,
      searchFromTypeAhead: state.typeaheadSearchStore.searchFromTypeAhead,
    }))
  );

  const { avatar, showDirection, providerId } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      avatar: state.detailsState.avatar,
      showDirection: state.detailsState.showDirection,
      providerId: state.detailsState.providerId,
      locationId: state.detailsState.locationId,
    }))
  );

  const { linkName } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      linkName: state.analyticsState.linkName,
    }))
  );

  const isProviderCardSelected =
    storage.session.get(
      Constants.STORAGE_KEYS.SESSION.IS_PROVIDER_CARD_SELECTED
    ) ?? false;

  const selectedProviderPrimarySpecialty = storage.session.get(
    Constants.STORAGE_KEYS.SESSION.SELECTED_PROVIDER_SPECIALTY
  );

  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();

  const enableSendFeedback = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_SUGGEST_AN_EDIT_FORM
  );

  const { providerId: storeProviderId, locationId: storeLocationId } =
    useInitializeStore(ConstantsRoutes.PROVIDER_GROUP_DETAILS.key);

  const [navigateToDirections, setNavigateToDirections] = useState(
    Boolean(showDirection)
  );
  const [isMapView, setIsMapView] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState(
    navigateToDirections ? LOCATIONS_TAB_INDEX : 0
  );

  const storedLocation = getGeoLocationFromStorage();

  const { data, isLoading } = useProviderDetails({
    providerType: ProviderType.MEDICAL_GROUP,
    providerId: storeProviderId,
    locationId: storeLocationId,
  });

  const [, setDirectionsTriggeredFrom] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.DIRECTIONS_TRIGGERED_FROM,
    ''
  );

  useEffect(() => {
    if (
      data?.facilityDetails?.providerDetails?.providerName &&
      searchFromTypeAhead
    ) {
      adobeStandardSearchEvent({
        linkName,
        term: searchTerm,
        numberOfResults: 1,
        method: AdobeEventMethods.Predictive,
        customAttributesBlock: {
          searchLocation: getSearchLocation(storedLocation),
          providerType: internalConvertProviderTypeToAdobeType(
            ProviderTypes.MEDICALGROUP
          ),
        },
      });
    }
  }, [
    data?.facilityDetails?.providerDetails?.providerName,
    searchFromTypeAhead,
  ]);

  useEffect(() => {
    if (showDirection) {
      setDirectionsTriggeredFrom('');
      setNavigateToDirections(true);
    }
  }, [showDirection]);

  useEffect(() => {
    if (navigateToDirections) {
      setActiveTab(LOCATIONS_TAB_INDEX);
    }
  }, [navigateToDirections]);

  const handleTabChange = (index) => {
    if (index !== LOCATIONS_TAB_INDEX) {
      setNavigateToDirections(false);
    }
    adobeLinkTrackEvent({
      name: Object.values(Constants.PROVIDER_GROUP_DETAILS.TABS_LIST)[index],
      location: `body:details tabs`,
      type: 'tab',
    });
    setActiveTab(index);
  };

  const providerGroupDetails = data?.facilityDetails?.providerDetails || {};
  const providerLocations = providerGroupDetails?.providerLocations || [];
  let providerSpecialties = providerGroupDetails?.specialties;

  if (isProviderCardSelected) {
    providerSpecialties = [selectedProviderPrimarySpecialty];
  }

  const [selectedId] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    ''
  );

  const selectedLocationIndex = getSelectedLocationIndex(
    providerLocations,
    selectedId
  );
  const selectedLocation = providerLocations?.[selectedLocationIndex];

  const networkStatus = getNetworkStatus(selectedLocation?.network?.status);

  return (
    <React.Fragment>
      {isLoading ? (
        <LoadingSpinnerComp isLoading={isLoading} />
      ) : (
        <React.Fragment>
          {!(isMapView && mobileScreen) ? (
            <CardWrapperDetailsPage backgroundColor="#FBFCFE">
              <DetailsPageHeader
                avatar={avatar}
                coverageType={coverageType}
                handleTabChange={handleTabChange}
                imageLocation={providerGroupDetails?.imageLocation}
                inNetwork={networkStatus}
                primarySpeciality={providerSpecialties}
                professionalDesignation={undefined}
                providerId={providerId}
                providerLocations={providerLocations}
                providerName={providerGroupDetails?.providerName}
                providerType={ProviderType.PROVIDER_GROUP}
                sectionTypeValue={Constants.RESULT_SECTION.PROVIDER_GROUPS}
                setNavigateToDirections={setNavigateToDirections}
                virtualCareOffered={selectedLocation?.virtualCareOffered}
              />
            </CardWrapperDetailsPage>
          ) : null}
          <CardWrapperDetailsPage>
            <ProviderDetailTabs
              active={activeTab}
              chipValue={chipValue}
              coverageType={coverageType}
              handleTabChange={handleTabChange}
              isMapView={isMapView}
              navigateToDirections={navigateToDirections}
              providerDetails={providerGroupDetails}
              setIsMapView={setIsMapView}
              setNavigateToDirections={setNavigateToDirections}
            />
          </CardWrapperDetailsPage>
        </React.Fragment>
      )}
      {enableSendFeedback && (
        <SendFeedback
          coverageType={coverageType}
          data={{
            name: providerGroupDetails?.providerName,
            address: selectedLocation?.address,
            npi: providerGroupDetails?.npi,
            mpin: providerGroupDetails?.providerId,
            providerType: providerGroupDetails?.providerType,
          }}
          type={Constants.FEEDBACK_FORM_TYPES.PROVIDER_GROUP_DETAILS}
        />
      )}
    </React.Fragment>
  );
};
