import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { Layout } from '@abyss/web/ui/Layout';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChoosePCPAlert } from '../../../../common/Alert/ChoosePCPAlert';
import { Constants } from '../../../../common/Constants';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { CompareProvider } from '../../../../models/Provider';
import { DownloadResultsButton } from '../ViewAll/DownloadResultsButton';
import { ShareCompareButton } from '../ViewAll/ShareCompareButton';

type Props = {
  heading?: string;
  sectionType: string;
  openCompare?: boolean;
  setOpenCompare: (a: boolean) => void;
  setOpenShare: (a: boolean) => void;
  setSelectedCheckbox: (a: { checked: {} }) => void;
  setSelectedItems: (a: CompareProvider[]) => void;
  setOpenBoxContents: (a: boolean) => void;
  openShare: boolean;
  search?: string | null;
  pageSize?: number;
  pageNumber?: number;
  results: any[];
};

export const MapViewHeaderEnhancement = ({
  heading,
  sectionType,
  openCompare,
  setOpenCompare,
  setOpenShare,
  setSelectedCheckbox,
  setSelectedItems,
  openShare,
  setOpenBoxContents,
  search,
  pageSize,
  pageNumber,
  results,
}: Props) => {
  const { t } = useTranslation();
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [enableMapViewEnhancements] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_LIST_VIEW_MAP_ENHANCEMENTS,
  ]);
  const translatedText = heading?.length
    ? t(`${heading}`)
    : t(`${search}`) || '';

  return (
    <React.Fragment>
      <Layout.Stack
        alignItems="left"
        css={{ '@screen < $sm': { display: 'none' } }}
        grow="true"
        space="0"
      >
        <Flex
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            maxWidth: '450px',
          }}
        >
          <Flex
            css={{
              paddingBottom: '10px',
              '.abyss-text-root': {
                fontSize: '14px',
                lineHeight: '18px',
              },
              '.abyss-icon-material': {
                height: '18px',
                width: '18px',
              },
            }}
          >
            <Heading
              css={{
                'abyss-heading-root': {
                  fontSize: '22.78px !important',
                  lineHeight: '28px !important',
                  marginBottom: '12px',
                  width: '450px',
                },
              }}
              data-auto-testid="results-heading"
              data-testid="results-heading"
              display="h4"
              offset={1}
              textAlign="left"
            >
              {translatedText}
            </Heading>
            <Layout.Group space="15">
              <Layout.Group space="15">
                <React.Fragment>
                  {sectionType === Constants.RESULT_SECTION.PROVIDER && (
                    <ShareCompareButton
                      isShareButton={false}
                      openCompare={openCompare}
                      sectionType={sectionType}
                      setIsClicked={setIsClicked}
                      setOpenCompare={setOpenCompare}
                      setOpenShare={setOpenShare}
                      setSelectedCheckbox={setSelectedCheckbox}
                      setSelectedItems={setSelectedItems}
                      text={t('COMPARE_BUTTONS.COMPARE')}
                      view="desktop"
                    />
                  )}
                  <ShareCompareButton
                    openShare={openShare}
                    sectionType={sectionType}
                    setIsClicked={setIsClicked}
                    setOpenCompare={setOpenCompare}
                    setOpenShare={setOpenShare}
                    setSelectedCheckbox={setSelectedCheckbox}
                    setSelectedItems={setSelectedItems}
                    text={t('COMPARE_BUTTONS.SHARE')}
                    view="desktop"
                  />
                  <DownloadResultsButton
                    isClicked={isClicked}
                    pageSize={pageSize}
                    printPage={pageNumber}
                    results={results}
                    setIsClicked={setIsClicked}
                    setOpenBoxContents={setOpenBoxContents}
                    setOpenCompare={setOpenCompare}
                    setOpenShare={setOpenShare}
                    setSelectedCheckbox={setSelectedCheckbox}
                    setSelectedItems={setSelectedItems}
                    text={t('COMPARE_BUTTONS.DOWNLOAD_RESULTS')}
                    view="desktop"
                  />
                </React.Fragment>
              </Layout.Group>
            </Layout.Group>
          </Flex>
        </Flex>
        {!enableMapViewEnhancements && <ChoosePCPAlert isMapView />}
      </Layout.Stack>
    </React.Fragment>
  );
};
