import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';
import find from 'lodash/find';
import React, { useState } from 'react';

import { useLagoon } from '../../hooks/useLagoon';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { phoneOnly } from '../ConstantsStyles';
import {
  distanceInfoIcon,
  distancePopover,
  drawerStyle,
} from '../RadioGroup/DistanceInfoIcon.styled';
import { boardCertifiedInfoIconText } from './BoardCertifiedInfoIcon.styled';

export const BoardCertifiedInfoIcon = () => {
  const [isOpen, setIsOpen] = useState(false);
  const mobileScreen = useMediaQuery(phoneOnly);
  const uiMessaging = useLagoon('ui-messaging')();
  const boardCertifiedInfoIconContent = find(uiMessaging, {
    key: ConstantsLagoon.CONTENT_FILTERS.BOARD_CERTIFIED_INFO_ICON,
  });
  const boardCertifiedInfoText = find(uiMessaging, {
    key: ConstantsLagoon.CONTENT_FILTERS.BOARD_CERTIFIED_INFO_TEXT,
  });
  const content = () => (
    <Flex
      css={{
        'abyss-flex-root': {
          padding: '16px 0px 0px 0px',
        },
      }}
      data-testid="board-certified-info-icon-popover"
      onClick={() => setIsOpen(true)}
    >
      <IconMaterial
        css={distanceInfoIcon}
        icon="info"
        size={24}
        variant="outlined"
      />
      <Text
        css={boardCertifiedInfoIconText}
        data-testid="board-certified-info-text"
      >
        {boardCertifiedInfoText.title}
      </Text>
    </Flex>
  );

  return (
    <React.Fragment>
      {!mobileScreen ? (
        <Popover
          content={boardCertifiedInfoIconContent.message}
          css={distancePopover}
          data-testid="board-certified-info-icon-popover"
          onOpenChange={() => {
            setIsOpen(!isOpen);
          }}
          open={isOpen}
          title={boardCertifiedInfoIconContent.title}
          width={220}
        >
          {content()}
        </Popover>
      ) : (
        <React.Fragment>
          {content()}
          <Drawer
            css={drawerStyle}
            data-auto-testid="board-certified-info-icon-popover"
            data-testid="board-certified-info-icon-popover"
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            position="bottom"
            title={boardCertifiedInfoIconContent.title}
            titleAlign="left"
          >
            <Drawer.Section>
              {boardCertifiedInfoIconContent.message}
            </Drawer.Section>
          </Drawer>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
