import { APIConstants } from '../../../../api/src/services/Constants';
import { ProviderTypes } from '../Constants';

export const toShowKSBannerOnProviderDetailsAbout = (
  coverageType,
  providerDetails,
  currentMember,
  ksRegularProviderTins
) => {
  const { providerType } = providerDetails;
  const { tin } = providerDetails?.providerLocations[0];
  const { isKelseySeyboldPlan } = currentMember;
  return (
    isKelseySeyboldPlan &&
    coverageType === APIConstants.COVERAGE_TYPES.MEDICAL &&
    !(ksRegularProviderTins?.split(',').includes(tin)) &&
    (providerType.toLowerCase() === ProviderTypes.MEDICALGROUP.toLowerCase() ||
      providerType.toLowerCase() === ProviderTypes.PRACTITIONER.toLowerCase())
  );
};
