import {
  Constants,
  CoverageTypesCodes,
  PopulationType,
  UpdatePcpErrorResponse,
} from '../common/Constants';
import { convertObjectToUrlParams } from '../common/Utils';
import {
  isCnsMember,
  isMnrMember,
} from '../common/Utils/memberUtils/memberUtils';
import { getMemberHealthCoverageType } from './providerSearch.utils';

export const getPCPGetParams = (
  enablePreProd: boolean,
  loggedInMember,
  memberId: string
) => {
  const {
    demographics,
    eligibility,
    familyId,
    isGatedUser,
    isNonGatedUSPUser,
    lineOfBusiness,
    membershipCategory,
    policyNumber,
    population,
    reciprocityId,
  } = loggedInMember;

  const { name, dateOfBirth, dependentSeqNbr } = demographics;

  const medicalCoverage =
    getMemberHealthCoverageType(eligibility, CoverageTypesCodes.MEDICAL) || {};
  const {
    claimSystemTypeCode,
    coverageID,
    eligibilitySystemTypeCode,
    encryptedUserNameWithPlanId,
    marketType,
    planVariationCode,
  } = medicalCoverage;

  const coverageId = coverageID?.find(
    (cov) => cov.sourceSysCode === 'SUBSCRIBER ID'
  );

  return {
    firstName: name.firstName,
    lastName: name.lastName,
    groupNumber: policyNumber,
    memberId,
    claimSystemTypeCode,
    dateOfBirth,
    dependentSeqNbr,
    eligibilitySystemTypeCode,
    enablePreProd,
    familyId,
    id: encryptedUserNameWithPlanId,
    isGatedUser,
    isNonGatedUSPUser,
    lob: lineOfBusiness,
    membershipCategory,
    planVariationCode,
    population,
    portalSource: marketType,
    reciprocityId,
    userName: coverageId?.id,
  };
};

export const getPCPUpdateParams = (
  dependentSeqNbr: string | undefined,
  loggedInMember,
  memberByDependentSeqNbr,
  memberId: string,
  pcpId: string,
  providerType: string,
  providerName: string
) => {
  const { demographics: loggedInDemographics } = loggedInMember;
  const { dependentSeqNbr: loggedInDepSeqNbr } = loggedInDemographics;
  const {
    demographics,
    eligibility,
    familyId,
    isGatedUser,
    isNonGatedUSPUser,
    lineOfBusiness,
    policyNumber,
  } = memberByDependentSeqNbr;
  const { dateOfBirth, name } = demographics;
  const { firstName, lastName } = name;

  const medicalCoverage =
    getMemberHealthCoverageType(eligibility, CoverageTypesCodes.MEDICAL) || {};
  const { coverageID, encryptedUserNameWithPlanId, marketType } =
    medicalCoverage;

  const coverageId = coverageID?.find(
    (cov) => cov.sourceSysCode === 'SUBSCRIBER ID'
  );

  return {
    firstName,
    lastName,
    dateOfBirth,
    groupNumber: policyNumber,
    memberId,
    dependentSeqNbr,
    familyId,
    pcpId,
    pcpName: providerName,
    submittedByDependentSequenceNumber: loggedInDepSeqNbr,
    providerType,
    isGatedUser,
    isNonGatedUSPUser,
    id: encryptedUserNameWithPlanId,
    lob: lineOfBusiness,
    portalSource: marketType,
    userName: coverageId?.id,
  };
};

// Returns the lagoon key value to pull error modal content from the Update PCP Error Modal table
export const getErrorModalContentKey = (
  populationType: PopulationType,
  errorCode: string = '',
  errorMessage: string = ''
): string => {
  const message = errorMessage?.toLowerCase();
  if (populationType === PopulationType.USP) {
    switch (errorCode) {
      case '50127':
        return '3';
      default:
        return '2';
    }
  }

  if (
    (errorCode === '000' &&
      message === UpdatePcpErrorResponse.noPrimaryCareRecords) ||
    (errorCode === '500352' &&
      message === UpdatePcpErrorResponse.noPcpNetworkFound)
  ) {
    return '1';
  }

  switch (errorCode) {
    case '40423':
    case '20003':
      return '1';
    default:
      return '2';
  }
};

export const getShowEditPCP = (editPcp, lockInIndicator) =>
  editPcp && !lockInIndicator;

export const getIsFuturePCPCardSeparate = (lob) =>
  isMnrMember(lob) || isCnsMember(lob);

export const showFuturePCPText = (lob, futurePCP, isPreEffective) =>
  !!futurePCP && !isPreEffective && !getIsFuturePCPCardSeparate(lob);

export const showFuturePCPCard = (lob, futurePCP, isPreEffective) =>
  !!futurePCP && !isPreEffective && getIsFuturePCPCardSeparate(lob);

export const doChoosePCPStoreUpdate = (
  dependentSeqNbr,
  chipStore,
  setTypeaheadSearchStore,
  setPCPSearchState
) => {
  const typeaheadStoreValues = {
    search: Constants.RESULT_SECTION.PRIMARY_CARE_PROVIDERS,
    searchType: Constants.SEARCH_TYPES.SPECIALTY,
    providerType: '',
  };
  setTypeaheadSearchStore({
    ...typeaheadStoreValues,
    resultType: Constants.RESULT_SECTION.ALL,
    acceptingNewPatients: true,
  });
  const pcpStoreValues = {
    choosePCP: true,
    dependentSeqNbr,
    pcpIndicator: true,
  };
  setPCPSearchState(pcpStoreValues);

  return convertObjectToUrlParams(chipStore, {
    ...typeaheadStoreValues,
    ...pcpStoreValues,
  });
};
