import { useForm } from '@abyss/web/hooks/useForm';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { useEffect, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import { ConstantsLagoon } from '../../../common/ConstantsLagoon';
import { NonTierOneLocation } from '../../../common/ProviderDetailTabs/ProviderLocationsDetails/NonTierOneLocation';
import { getTierProviderEligibility } from '../../../common/Utils/memberUtils/memberUtils';
import { useAdobePageTrackEvent } from '../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { useLagoon } from '../../../hooks/useLagoon';
import {
  FacilityDetails,
  FacilityLocationsResponse,
} from '../../../models/FacilityDetails';
import { useAnalyticsStore } from '../../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../../store/useAnalyticsStore/analyticsStore';
import { useTypeaheadStore } from '../../../store/useTypeaheadStore';
import { TypeaheadState } from '../../../store/useTypeaheadStore/typeaheadStore';
import { isVirtualCareAvailableInLocation } from '../../../utils/indicator.utils';
import {
  FacilityDDPCode,
  UserDDPCode,
  areAllProviderLocationsNonTieredCheck,
  getSelectedLocationIndex,
  getSuppressFacilityFlags,
  isShowPreferredFacilityBadge,
  providerHasLocations,
  showNonTierLocationContent,
  showPremiumFacilityBadgeOnHeader,
  showTier2ProviderTagOnHeader,
  showTierProviderTagOnHeader,
} from '../../../utils/providerDetails.utils';
import {
  getCurrentMember,
  getIsOxfordAndUSP,
  getLoggedInMember,
} from '../../../utils/user.utils';
import { adobeLinkTrackEvent } from '../../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../Constants';
import { FeatureFlags } from '../../ConstantsFeatureFlags';
import { h2SmallerFontForResponsive, phoneOnly } from '../../ConstantsStyles';
import { LinkWithTracking } from '../../LinkWithTracking/LinkWithTracking';
import { MapViewLocationsWrap } from '../../MapViewLocationsWrap/MapViewLocationsWrap';
import { MapViewUpdateLocationButton } from '../../MapViewUpdateLocationButton/MapViewUpdateLocationButton';
import { InfoProvider } from '../../ProviderDetailTabs/ProviderLocationsDetails/InfoProvider';
import { LocationsContentForPopOver } from '../../ProviderDetailTabs/ProviderLocationsDetails/LocationsContentForPopOver';
import { PopOver } from '../../ProviderDetailTabs/ProviderLocationsDetails/PopOver';
import {
  Container,
  InnerContainer,
  LocationsHeader,
  MapViewBoxContainer,
  MapViewBoxContainerFullWidth,
  SectionContainer,
  ViewOnMapLabel,
} from '../../ProviderDetailTabs/ProviderLocationsDetails/ProviderLocation.style';
import { getFeatureFlag } from '../../Utils';
import {
  convertCoverageType,
  convertProviderTypeToAdobeType,
  convertSpecialtiesList,
  getSearchBlock,
} from '../../Utils/adobeTrackUtils/adobeTrackUtils';
import { extractRollupCodesParts } from '../Utils';

type Props = {
  facilityDetails: FacilityDetails;
  setUpdateLocation: (a: boolean) => void;
  navigateToDirections?: boolean;
  setNavigateToDirections: (a: boolean) => void;
  tabTitle?: string;
  isMapView?: boolean;
  setIsMapView: (a: boolean) => void;
  coverageType: string;
};

const showNonTierLocationBadgeUSPUNET = (
  population,
  unetNonTierOneLocationTag,
  uspNonTierOneLocationTag,
  isTier1Eligible
) =>
  showNonTierLocationContent(
    population,
    unetNonTierOneLocationTag,
    uspNonTierOneLocationTag,
    !isTier1Eligible
  );

const getIndicatorsForImpressions = (
  selectedLocation,
  { showTierProviderTag, showBenefitsBadge }
) => {
  const indicators: string[] = [];

  const featureFlags = useLagoon('feature-flags')();
  const currentMember = getCurrentMember();
  const showTier2Badge: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_TIER_TWO_PROVIDER
  );

  if (selectedLocation?.acceptingNewPatients)
    indicators.push(Constants.ADOBE_TRACKING.NEW_PATIENTS);

  if (isVirtualCareAvailableInLocation(selectedLocation))
    indicators.push(
      Constants.ADOBE_TRACKING.VIRTUAL_CARE_OFFERED_LOCAL_PROVIDER
    );

  if (selectedLocation?.hasPCPServiceAvailability)
    indicators.push(Constants.ADOBE_TRACKING.PCP_SERVICES);

  if (selectedLocation?.hasEveningAppointments)
    indicators.push(Constants.ADOBE_TRACKING.EVENING_APPOINTMENT);

  if (selectedLocation?.ddpCode)
    indicators.push(`ddp ${selectedLocation.defaultTranslatedValues.ddpCode}`);
  if (
    showPremiumFacilityBadgeOnHeader(
      showTierProviderTag,
      getTierProviderEligibility(
        currentMember,
        selectedLocation?.isTieredProvider
      ),
      showBenefitsBadge
    ) &&
    !showTier2ProviderTagOnHeader(
      showTier2Badge,
      currentMember?.isIFPTieredPlan,
      selectedLocation?.isTier2Provider
    )
  ) {
    indicators.push(Constants.ADOBE_TRACKING.PREFERRED_FACILITY);
  }
  return indicators?.join('|').toLocaleLowerCase();
};

const handleSetSelectLocation = (
  locations,
  setSelectedLocation,
  facilityIndex
) => {
  if (locations) {
    setSelectedLocation(locations[facilityIndex]);
  }
};

const getProviderTierIndicator = (
  showTierProviderTag: boolean,
  selectedLocation: FacilityLocationsResponse
) => {
  const currentMember = getCurrentMember();
  const isTieredPlanEligible = getTierProviderEligibility(
    currentMember,
    selectedLocation?.isTieredProvider
  );
  return showTierProviderTagOnHeader(showTierProviderTag, isTieredPlanEligible)
    ? Constants.ADOBE_TRACKING.TIER_ONE
    : '';
};

const enableAdobeTracking = (
  selectedLocation: FacilityLocationsResponse,
  isMapView: boolean
) => selectedLocation && !isMapView;

export const FacilityLocation = ({
  facilityDetails,
  setUpdateLocation,
  navigateToDirections = false,
  setNavigateToDirections,
  tabTitle,
  isMapView = false,
  setIsMapView,
  coverageType,
}: Props) => {
  const locations = facilityDetails?.providerLocations;
  const hasMultipleLocations = providerHasLocations(facilityDetails);

  const [selectedId, setSelectedId] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    ''
  );
  const [directionsTriggeredFrom] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.DIRECTIONS_TRIGGERED_FROM,
    ''
  );

  const defaultTranslatedOrgTypes = extractRollupCodesParts(
    facilityDetails?.defaultTranslatedValues?.organizationType
  );

  const { navigate } = useRouter();

  const { t } = useTranslation();
  const featureFlags = useLagoon('feature-flags')();
  const mobileScreen = useMediaQuery(phoneOnly);

  const facilityIndex = getSelectedLocationIndex(locations, selectedId);

  const [currentIndex, setCurrentIndex] = useState<number>(facilityIndex);
  const [selectedAddressIndex, setSelectedAddressIndex] =
    useState<number>(facilityIndex);

  const [selectedLocation, setSelectedLocation] =
    useState<FacilityLocationsResponse>(locations?.[selectedAddressIndex]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [mobileRouteView, setMobileRouteView] = useState<boolean>(false);
  const ddpCodeUser = getLoggedInMember()?.ddpCode;
  const suppressFacilityFlags = getSuppressFacilityFlags();
  const showTierProviderTag = getFeatureFlag(
    featureFlags,
    FeatureFlags.FEATURE_FLAG_KEY_CHIP_MAP[
      Constants.CHIPS_CATEGORIES.PRIMARY_CARE
    ].TIER_ONE
  );
  const unetNonTierOneLocationTag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.UNET_NONTIER1_LOCATION
  );
  const uspNonTierOneLocationTag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_TIER1_ENABLE
  );
  const currentMember = getCurrentMember();

  const areAllProviderLocationsNonTiered =
    areAllProviderLocationsNonTieredCheck(locations);

  const [viewMapEnhancementsFlag] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_LIST_VIEW_MAP_ENHANCEMENTS,
  ]);

  const isTier1Eligible = getTierProviderEligibility(
    currentMember,
    selectedLocation?.isTieredProvider
  );

  const showNonTierLocationBadge = showNonTierLocationBadgeUSPUNET(
    currentMember?.population,
    unetNonTierOneLocationTag,
    uspNonTierOneLocationTag,
    isTier1Eligible
  );

  const preferredFacilityFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.PREFERRED_FACILITY_IDENTIFICATION
  );

  const oxfordPlnFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.OXFORD_PLN
  );

  const { isOXMember } = getIsOxfordAndUSP(
    currentMember,
    coverageType,
    featureFlags
  );
  const showBenefitsBadge = isShowPreferredFacilityBadge(
    suppressFacilityFlags,
    preferredFacilityFlag,
    selectedLocation?.ddpCode as FacilityDDPCode,
    (ddpCodeUser as UserDDPCode) || undefined,
    selectedLocation?.facilityProviderSpecialties?.isPreferredLabNetwork,
    selectedLocation?.isOxPreferredLab,
    isOXMember,
    selectedLocation?.facilityProviderSpecialties?.isFreeStandingFacility,
    oxfordPlnFlag
  );
  const { typeaheadState } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      typeaheadState: state.typeaheadSearchStore,
    }))
  );
  const { linkName } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      linkName: state.analyticsState.linkName,
    }))
  );

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: tabTitle,
    sitesectionLevel1: Constants.ADOBE_TRACKING.DETAILS_SITESECTION1,
    sitesectionLevel2: `${convertProviderTypeToAdobeType(
      facilityDetails.providerType
    )} details`,
    impressionBlock: {
      type: convertProviderTypeToAdobeType(facilityDetails.providerType),
      indicator: getIndicatorsForImpressions(selectedLocation, {
        showBenefitsBadge,
        showTierProviderTag,
      }),
      providerTier: getProviderTierIndicator(
        showTierProviderTag,
        selectedLocation
      ),
    },
    providerBlock: {
      type: convertCoverageType(coverageType),
      specialty: convertSpecialtiesList(defaultTranslatedOrgTypes),
      role: 'facility',
    },
    searchBlock: getSearchBlock(typeaheadState, linkName),
  });

  useEffect(() => {
    handleSetSelectLocation(locations, setSelectedLocation, facilityIndex);
  }, [locations]);

  useEffect(() => {
    if (enableAdobeTracking(selectedLocation, isMapView)) adobePageTrackEvent();
  }, [selectedLocation, isMapView, showBenefitsBadge, showTierProviderTag]);

  useEffect(() => {
    if (locations?.[selectedAddressIndex]) {
      const selectedIndex: string = selectedAddressIndex.toString();
      const location = locations[selectedIndex];
      const { locationId } = location;
      setSelectedLocation(location);
      setSelectedId(locationId);
    }
  }, [selectedAddressIndex]);

  useEffect(() => {
    if (locations) {
      const index = locations.findIndex(
        (item) => item.locationId === selectedLocation?.locationId
      );
      setSelectedAddressIndex(index);
      setCurrentIndex(index);
    }
  }, [selectedLocation]);

  useEffect(() => {
    setIsMapView(navigateToDirections);
  }, [navigateToDirections]);

  const handleHideMap = () => {
    setNavigateToDirections(false);
    setIsMapView(false);
    adobeLinkTrackEvent({
      name: 'back',
      location: `body:view on map`,
      type: 'internal',
    });
    if (
      directionsTriggeredFrom !==
      Constants.DETAILS_PAGE_HEADER_DIRECTIONS_BUTTON
    ) {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (!mobileRouteView && mobileScreen && navigateToDirections)
      handleHideMap();
  }, [mobileRouteView]);

  const form = useForm({
    defaultValues: {
      'radio-form': '',
    },
  });

  const handleViewMap = () => {
    setIsMapView(true);
  };

  const handleUpdateLocation = () => {
    adobeLinkTrackEvent({
      name: 'save location',
      location: `body:${Constants.PROVIDER_DETAILS.VIEWONMAP}`,
      type: 'internal',
    });
    setUpdateLocation(true);
    setIsMapView(false);
    setSelectedAddressIndex(currentIndex);
    setNavigateToDirections(false);
  };

  const handleSelectedLocation = (location, index) => {
    setSelectedLocation(location);
    setSelectedAddressIndex(index);
    setAlertVisible(true);
  };

  if (!selectedLocation) {
    return null;
  }

  const useStickyButtonRow = hasMultipleLocations && !mobileScreen;
  const isSameLocationSelected = selectedAddressIndex === currentIndex;

  return !isMapView ? (
    <Container
      data-auto-testid="facility-locations-details-section"
      data-testid="facility-locations-details-section"
    >
      <FormProvider state={form}>
        <SectionContainer>
          <LocationsHeader justify="flex-end">
            <Heading
              css={h2SmallerFontForResponsive}
              data-auto-testid="directory-update-title"
              data-testid="directory-update-title"
              display="h4"
              offset={1}
            >
              {t('PROVIDER_DETAILS.LOCATIONS')}
              {` (${facilityDetails.providerLocations.length})`}
            </Heading>
            <Layout.Group
              css={{
                marginTop: '10px',
                '@screen < $md': { marginTop: '0px' },
              }}
            >
              <LinkWithTracking
                analyticsInfo={{
                  location: 'body:locations',
                  name: Constants.PROVIDER_DETAILS.VIEWONMAP,
                }}
                data-auto-testid="view-facility-locations-on-map"
                data-testid="view-facility-locations-on-map"
                href="#"
                isStandardAnchor
                onClick={handleViewMap}
              >
                <IconMaterial
                  color="$interactive1"
                  css={{
                    width: '24px',
                    height: '24px',
                    marginRight: '4px',
                    '@screen < $sm': {
                      width: '18px',
                      height: '18px',
                    },
                  }}
                  icon="map"
                />
                <ViewOnMapLabel color="$interactive1">
                  {t('PROVIDER_DETAILS.VIEWONMAP')}
                </ViewOnMapLabel>
              </LinkWithTracking>
            </Layout.Group>
          </LocationsHeader>
          <PopOver
            isOpen={isOpen}
            locationsContentForPopOver={
              <LocationsContentForPopOver
                isOpen={isOpen}
                locationsDetails={locations}
                mobileScreen={mobileScreen}
                selectedIndex={selectedAddressIndex}
                selectedLocation={selectedLocation}
                setIsOpen={setIsOpen}
                setSelectedLocation={handleSelectedLocation}
              />
            }
            locationsDetails={locations}
            selectedLocation={selectedLocation}
            setIsOpen={setIsOpen}
          />
          <NonTierOneLocation
            areAllProviderLocationsNonTiered={areAllProviderLocationsNonTiered}
            isCns={currentMember?.isCnSMember}
            showNonTierLocationBadge={showNonTierLocationBadge}
          />
        </SectionContainer>
      </FormProvider>
      <InnerContainer>
        <InfoProvider
          providerDetails={facilityDetails}
          selectedLocation={selectedLocation}
        />
      </InnerContainer>
    </Container>
  ) : (
    <Container
      data-auto-testid="facility-locations-details-section"
      data-testid="facility-locations-details-section"
    >
      {viewMapEnhancementsFlag ? (
        <MapViewBoxContainerFullWidth>
          <MapViewLocationsWrap
            directionsButtonClicked={navigateToDirections}
            handleGoBack={handleHideMap}
            handleUpdateLocation={handleUpdateLocation}
            hasMultipleLocations={hasMultipleLocations}
            isMapView={isMapView}
            mobileRouteView={mobileRouteView}
            mobileScreen={mobileScreen}
            providerDetails={facilityDetails}
            setCurrentIndex={setCurrentIndex}
            setIsMapView={setIsMapView}
            setMobileRouteView={setMobileRouteView}
            tabTitle={tabTitle}
            updateLocationButton={
              <MapViewUpdateLocationButton
                handleSaveLocation={handleUpdateLocation}
                isSameLocationSelected={isSameLocationSelected}
              />
            }
            useStickyButtonRow={useStickyButtonRow}
          />
        </MapViewBoxContainerFullWidth>
      ) : (
        <MapViewBoxContainer>
          <MapViewLocationsWrap
            directionsButtonClicked={navigateToDirections}
            handleGoBack={handleHideMap}
            handleUpdateLocation={handleUpdateLocation}
            hasMultipleLocations={hasMultipleLocations}
            isMapView={isMapView}
            mobileRouteView={mobileRouteView}
            mobileScreen={mobileScreen}
            providerDetails={facilityDetails}
            setCurrentIndex={setCurrentIndex}
            setIsMapView={setIsMapView}
            setMobileRouteView={setMobileRouteView}
            tabTitle={tabTitle}
            updateLocationButton={
              <MapViewUpdateLocationButton
                handleSaveLocation={handleUpdateLocation}
                isSameLocationSelected={isSameLocationSelected}
              />
            }
            useStickyButtonRow={useStickyButtonRow}
          />
        </MapViewBoxContainer>
      )}
    </Container>
  );
};
