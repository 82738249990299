import { storage } from '@abyss/web/tools/storage';

import { Constants, NULL_RESULTS } from '../common/Constants';
import { ConstantsRoutes } from '../common/ConstantsRoutes';
import {
  getLinkNameForAnalytics,
  getReqCoverageType,
  getReqCoverageTypes,
  getSearchMethodForAnalytics,
  handleSearchSelected,
  isCommonSearch,
} from '../common/PSXHeader/SearchBar/utils';
import { convertObjectToUrlParams } from '../common/Utils';

export const handleSearchBarSuggestionClickOrEnter = (args: any) => {
  const {
    enableRetriveTypeAheadData,
    specialityPreferenceTable,
    value,
    searchTerm,
    getCoverageType,
    dependentSeqNbr,
    choosePCP,
    chipStore,
    setTypeaheadSearchStore,
    setAnalyticsState,
    setPCPSearchState,
    setChipValue,
    setCoverageType,
    setDetailsStore,
    navigate,
  } = args;
  const commonSearch = isCommonSearch(searchTerm);
  const typeaheadValues = handleSearchSelected(
    enableRetriveTypeAheadData,
    specialityPreferenceTable,
    {
      ...value,
      searchTerm,
      resultType: value ? Constants.RESULT_SECTION.ALL : NULL_RESULTS,
      linkName: getLinkNameForAnalytics(value),
      typeaheadLinkName: `${getLinkNameForAnalytics(value)}-${value?.vertical}`,
      searchMethod: getSearchMethodForAnalytics(value),
      fromViewAll: false,
      includeSpecialityRollupCodes:
        value?.vertical?.code || value?.vertical || value?.pesKeyword,
      searchType:
        value?.vertical ||
        value?.pseudoVerticals ||
        value?.aoeCodes?.length > 0 ||
        commonSearch ||
        value?.bhProgramFacility
          ? Constants.SEARCH_TYPES.SPECIALTY
          : Constants.SEARCH_TYPES.NAME,
      coverageType: getReqCoverageType(value?.coverageType, getCoverageType),
      coverageTypes: getReqCoverageTypes(value?.coverageType, getCoverageType),
      dependentSeqNbr,
      choosePCP,
      bhProgramFacilityAgg: value?.bhProgramFacility,
    },
    {
      chipStore,
      setTypeaheadSearchStore,
      setAnalyticsState,
      setPCPSearchState,
      setChipValue,
      setCoverageType,
      setDetailsStore,
    },
    navigate
  );
  return typeaheadValues;
};

export const excludeDisabledResults = (
  memberCoverageType,
  suggestionResults
) => {
  const filteredResults: Object[] = [];
  suggestionResults.forEach((result) => {
    if (memberCoverageType.some((type) => result.coverageType.includes(type))) {
      filteredResults.push(result);
    }
  });

  return filteredResults;
};

export enum BrowseAllVariant {
  Specialty = 'specialty',
  Facility = 'facility',
}

export const handleOnSpecialtyOrFacilityClick = ({
  item,
  navigate,
  setTypeaheadSearchStore,
  setAnalyticsState,
  chipState,
  setDetailsStore,
  variant,
  enableRetriveTypeAheadData,
}) => {
  if (variant === BrowseAllVariant.Specialty) {
    if (item?.providerId) {
      const detailsStoreValues = {
        providerId: item.providerId,
      };
      setDetailsStore(detailsStoreValues);
      storage.session.set(
        Constants.STORAGE_KEYS.SESSION.SELECTED_PROVIDER_ID,
        item.providerId
      );
      const urlParams = convertObjectToUrlParams(chipState, detailsStoreValues);
      navigate(`${ConstantsRoutes.PROVIDER_DETAILS.path}${urlParams}`);
      return null;
    } else if (item.codes) {
      const providerGroupRollUpCodes: string[] = item.codes
        .map((codes) => {
          const firstRollUpCode = `52-526-050${codes.slice(2)}`;
          const secondRollUpCode = `52-526-033${codes.slice(2)}`;

          return [firstRollUpCode, secondRollUpCode];
        })
        .flat()
        .join(',');

      const rollUpCodes = `${providerGroupRollUpCodes},${item.codes.join(',')}`;

      setAnalyticsState({
        linkName: item.name,
        originLinkNameForAnalytics: item.name,
      });
      const typeaheadStoreValues = {
        search: item.name,
        includeSpecialityRollupCodes: rollUpCodes || '',
        combinedIncludeSpecialityRollupCodes: rollUpCodes || '',
        providerType: '',
        searchType: Constants.SEARCH_TYPES.SPECIALTY,
        specialtyCode: item.codes.join(',') || '',
        sectionType: Constants.RESULT_SECTION.PROVIDER_CAPITAL,
        drawerName: Constants.DRAWER_NAME.BROWSE_ALL_SPECIALTIES,
        medicalSpeciality: true,
      };
      setTypeaheadSearchStore(typeaheadStoreValues);
      if (!enableRetriveTypeAheadData) {
        const urlParams = convertObjectToUrlParams(
          chipState,
          typeaheadStoreValues
        );
        navigate(`${ConstantsRoutes.PROVIDER_SEARCH_RESULTS.path}${urlParams}`);
        // we don't need to return anything as we are navigating to different page.
        return null;
        // enableRetriveTypeAheadData is turned on, navigate is provided for direct user search flow.
        // We need to pass only the independent variables to the url and navite to the search results page.
      } else if (navigate) {
        const urlParams = convertObjectToUrlParams(chipState, {
          search: item.name,
          searchType: 'specialty',
          searchMethod: 'specialty-drawer',
          source: item.name,
        });
        navigate(`${ConstantsRoutes.PROVIDER_SEARCH_RESULTS.path}${urlParams}`);
        // we don't need to return anything as we are navigating to different page.
        return null;
      } else {
        // navigate is not provided for broswer back button flow where
        // navigation is not required and it returns the store values.
        return typeaheadStoreValues;
      }
    }
  } else if (variant === BrowseAllVariant.Facility) {
    if (item.codes) {
      const rollUpCodes = item.codes.join(',');

      setAnalyticsState({
        linkName: item.name,
        originLinkNameForAnalytics: item.name,
      });
      const typeaheadStoreValues = {
        search: item.name,
        includeSpecialityRollupCodes: rollUpCodes || '',
        combinedIncludeSpecialityRollupCodes: rollUpCodes || '',
        providerType: '',
        searchType: Constants.SEARCH_TYPES.SPECIALTY,
        facilityCode: item.codes.join(',') || '',
        sectionType: Constants.RESULT_SECTION.PROVIDER_CAPITAL,
        drawerName: Constants.DRAWER_NAME.BROWSE_ALL_FACILITIES,
      };
      setTypeaheadSearchStore(typeaheadStoreValues);

      if (!enableRetriveTypeAheadData) {
        const urlParams = convertObjectToUrlParams(
          chipState,
          typeaheadStoreValues
        );
        navigate(`${ConstantsRoutes.PROVIDER_SEARCH_RESULTS.path}${urlParams}`);
        // we don't need to return anything as we are navigating to different page.
        return null;
        // enableRetriveTypeAheadData is turned on, navigate is provided for direct user search flow.
        // We need to pass only the independent variables to the url and navite to the search results page.
      } else if (navigate) {
        const urlParams = convertObjectToUrlParams(chipState, {
          search: item.name,
          searchType: 'specialty',
          searchMethod: 'facilities-drawer',
          source: item.name,
        });
        navigate(`${ConstantsRoutes.PROVIDER_SEARCH_RESULTS.path}${urlParams}`);
        // we don't need to return anything as we are navigating to different page.
        return null;
      } else {
        // navigate is not provided for broswer back button flow where
        // navigation is not required and it returns the store values.
        return typeaheadStoreValues;
      }
    }
  }
  return null;
};

export const handleOnCommonlySearchedClick = ({
  item,
  source,
  navigate,
  chipStore,
  setAnalyticsState,
  setPCPSearchState,
  setTypeaheadSearchStore,
  setSearchStore,
  getOnlineRetailers,
  mappingRollUpCodeToCategory,
  enableRetriveTypeAheadData,
}) => {
  setAnalyticsState({
    originLinkNameForAnalytics: item?.psxKeyword,
  });
  const pcpStoreValues = {
    choosePCP: false,
  };
  setPCPSearchState(pcpStoreValues);
  const typeaheadStoreValues = {
    search: item?.psxKeyword,
    heading: '',
    searchType: item?.searchType?.toLowerCase(),
    pesKeyword: item?.pesKeyword,
    providerType: item?.providerType ?? '',
    includeSpecialityRollupCodes: item?.pesKeyword,
    combinedIncludeSpecialityRollupCodes: item?.pesKeyword,
  };
  setTypeaheadSearchStore({
    ...typeaheadStoreValues,
    category: item?.category,
    resultType: Constants.RESULT_SECTION.ALL,
    keyword: item?.psxKeyword || item?.searchTerm,
    isUserTextSearch: false,
  });
  const searchStoreValues = {
    onlineRetailers: getOnlineRetailers(item?.isOnlineProviders),
  };
  setSearchStore(searchStoreValues);
  if (!enableRetriveTypeAheadData) {
    const urlParams = convertObjectToUrlParams(chipStore, {
      ...pcpStoreValues,
      ...typeaheadStoreValues,
      choosePCP: false,
      chipValue: mappingRollUpCodeToCategory(item?.pesKeyword),
      originLinkNameForAnalytics: item?.psxKeyword,
      resultType: Constants.RESULT_SECTION.ALL,
      coverageType: item?.coverageType,
      keyword: item?.psxKeyword || item?.searchTerm,
      onlineRetailers: getOnlineRetailers(item?.isOnlineProviders),
      includeSpecialityRollupCodes: item?.pesKeyword,
    });
    navigate(`${ConstantsRoutes.PROVIDER_SEARCH_RESULTS.path}${urlParams}`);
    return null;
  } else if (navigate) {
    const urlParams = convertObjectToUrlParams(chipStore, {
      search: item.psxKeyword,
      searchType: item?.searchType?.toLowerCase(),
      searchMethod: 'browse-for-care',
      source,
    });
    navigate(`${ConstantsRoutes.PROVIDER_SEARCH_RESULTS.path}${urlParams}`);
    // we don't need to return anything as we are navigating to different page.
    return null;
  } else {
    // navigate is not provided for broswer back button flow where
    // navigation is not required and it returns the store values.
    return typeaheadStoreValues;
  }
};
