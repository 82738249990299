import { styled } from '@abyss/web/tools/styled';
import { Brandmark } from '@abyss/web/ui/Brandmark';
import { Divider } from '@abyss/web/ui/Divider';
import { Flex } from '@abyss/web/ui/Flex';
import { Print } from '@abyss/web/ui/Print';
import { Text } from '@abyss/web/ui/Text';
import { format } from 'date-fns';
import find from 'lodash/find';
import React, { useContext } from 'react';
import { useShallow } from 'zustand/react/shallow';

import {
  CareCategories,
  Constants,
  PremiumCareConstants,
} from '../../../../../../common/Constants';
import { ConstantsLagoon } from '../../../../../../common/ConstantsLagoon';
import { isMnrMember } from '../../../../../../common/Utils/memberUtils/memberUtils';
import { PortalContext } from '../../../../../../context/PortalContext';
import { useLagoon } from '../../../../../../hooks/useLagoon';
import { CompareProvider } from '../../../../../../models/Provider';
import { useChipStore } from '../../../../../../store/useChipStore';
import { ChipState } from '../../../../../../store/useChipStore/chipStore';
import { DateTime } from '../../../../../../utils/DateTime';
import {
  getCurrentMember,
  isCNSMember,
} from '../../../../../../utils/user.utils';
import { getLanguage } from '../../../../context/Internationalization/helpers';
import { CompareCheckboxInfo } from '../../CompareDrawer/utility/compareDrawerConstants';
import { PrintExpandedCard } from './PrintExpandedCard';

export const PrintViewAddress = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '9px',
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: '11px',
  },
});

export const PrintViewDivider = styled(Divider, {
  '&.abyss-divider-root': {
    border: '0.5px solid #CCCCCC',
    height: 0,
    marginTop: '$sm !important',
    marginBottom: '$md !important',
    transform: 'matrix(1, 0, 0, 1, 0, 0)',
  },
});

export const PrintViewProviderMediumText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '14px',
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: '17px',
    marginBottom: '5px',
  },
});

export const PrintViewProviderBoldText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '10px',
    fontWeight: '700',
    fontStyle: 'normal',
    lineHeight: '11.87px',
  },
});

export const PrintViewProviderNormalText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '10px',
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: '11.87px',
  },
});

export const PrintViewProviderDetailsXSText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '11px',
    fontWeight: '400',
    lineHeight: '14px',
  },
});

type Props = {
  selectedProviders: CompareProvider[];
  compareCheckboxes: CompareCheckboxInfo[];
};

export const PrintCompareDrawer = ({
  selectedProviders,
  compareCheckboxes,
}: Props) => {
  const { chipValue } = useChipStore(
    useShallow((state: ChipState) => ({
      chipValue: state.chipValue,
    }))
  );

  const { lineOfBusiness } = getCurrentMember();
  const isMNR = isMnrMember(lineOfBusiness);
  const isCNS = isCNSMember(lineOfBusiness);
  const isMNROrCNSMember = isMNR || isCNS;

  const lagoonData = useLagoon('ui-messaging')();
  const printViewContent = find(lagoonData, {
    key: ConstantsLagoon.HEALTHGRADES.DISCLAIMER_IN_PAGE,
  });

  const visionDirectoryDisclaimer = find(lagoonData, {
    key: ConstantsLagoon.VISION_DICTIONARY.DISCLAIMER_CONTENT,
  });

  const visionDirectoryDisclaimerAddlContent = find(lagoonData, {
    key: ConstantsLagoon.VISION_DICTIONARY.DISCLAIMER_ADDL_CONTENT,
  });

  const dentalMnrDisclaimer = find(lagoonData, {
    key: ConstantsLagoon.MNR_DISCLAIMER.DENTAL_MNR_DISCLAIMER,
  });

  const medicalFacilityMnrDisclaimer = find(lagoonData, {
    key: ConstantsLagoon.MNR_DISCLAIMER.MEDICAL_FACILITY_MNR_DISCLAIMER,
  });

  const visionMnrDisclaimer = find(lagoonData, {
    key: ConstantsLagoon.MNR_DISCLAIMER.VISION_MNR_DISCLAIMER,
  });

  const directoryMnrDisclaimer = find(lagoonData, {
    key: ConstantsLagoon.MNR_DISCLAIMER.DIRECTORY_MNR_DISCLAIMER,
  });

  const locale = getLanguage().code;
  const premiumCareData = PremiumCareConstants[locale];
  const premiumDisclaimerContent = premiumCareData?.disclaimerContent ?? '';

  const renderVisionDisclaimer = () => {
    if (chipValue === Constants.CHIPS_CATEGORIES.VISION) {
      return `${visionDirectoryDisclaimer?.message ?? ''}
              <br><br/>
              ${visionDirectoryDisclaimerAddlContent?.message ?? ''}`;
    }

    return premiumDisclaimerContent;
  };

  const renderMnrDisclaimer = () => {
    switch (chipValue) {
      case CareCategories.DENTAL:
        return dentalMnrDisclaimer?.message.replace(/\n/g, '<br/>');
      case CareCategories.MEDICAL_SPECIALISTS:
      case CareCategories.FACILITIES:
        return medicalFacilityMnrDisclaimer?.message.replace(/\n/g, '<br/>');
      case CareCategories.ALL:
        return directoryMnrDisclaimer?.message.replace(/\n/g, '<br/>');
      case CareCategories.VISION:
        return visionMnrDisclaimer?.message.replace(/\n/g, '<br/>');
      default:
        return '';
    }
  };

  const { portalData } = useContext(PortalContext);
  const themeOverride = portalData?.portalConfig;

  const brandConfig = {
    affiliate: themeOverride ? 'optum' : 'uhc',
    name: themeOverride ? 'optum' : 'uhc',
    color: themeOverride ? 'orange' : 'blue',
  };
  return (
    <Print.Section css={{ border: 'none', padding: '10px' }}>
      <Flex alignContent="flex-start" direction="column">
        <Flex justify="space-between">
          <Brandmark
            affiliate={brandConfig?.affiliate}
            brand={brandConfig?.name}
            color={brandConfig?.color}
            css={{
              'abyss-brandmark': {
                width: '114.24px',
                height: '35.36px',
              },
            }}
            size="$lg"
            variant="lockup"
          />
        </Flex>
        <PrintViewDivider orientation="horizontal" />
        <Flex justify="space-between">
          <PrintViewProviderMediumText color="$black">
            Selected providers
          </PrintViewProviderMediumText>
          <Flex>
            <PrintViewProviderBoldText color="$black">
              Date:&nbsp;
            </PrintViewProviderBoldText>
            <PrintViewProviderNormalText color="$black">
              {format(DateTime.getCurrentDateTime(), 'MMMM dd, yyyy')}
            </PrintViewProviderNormalText>
          </Flex>
        </Flex>
        <Flex justify="space-between">
          {/* <Flex>
            <PrintViewProviderBoldText color="$black">
              Provider Status:&nbsp;
            </PrintViewProviderBoldText>
            <PrintViewProviderNormalText color="$black">
              Network
            </PrintViewProviderNormalText>
          </Flex> */}
          {/* <Flex>
            <PrintViewProviderBoldText color="$black">
              Patient Account Number:&nbsp;
            </PrintViewProviderBoldText>
            <PrintViewProviderNormalText color="$black">
              FG3355699098
            </PrintViewProviderNormalText>
          </Flex> */}
        </Flex>
        <PrintViewDivider orientation="horizontal" />
        {selectedProviders?.length ? (
          <Flex
            data-auto-testid="print-expanded-card"
            data-testid="print-expanded-card"
            direction="row"
            gutters
          >
            {selectedProviders.map((item) => (
              <React.Fragment key={item.locationId}>
                <PrintExpandedCard
                  compareCheckboxes={compareCheckboxes}
                  item={item}
                />
              </React.Fragment>
            ))}
          </Flex>
        ) : (
          <Print.Value>No Comparisions to display.</Print.Value>
        )}
      </Flex>
      <Flex
        alignContent="center"
        css={{ marginLeft: '25px', display: 'flex', width: '95%' }}
      >
        <Flex
          alignContent="flex-start"
          css={{
            display: 'flex',
            color: '#333',
            fontFamily: '$primary',
            fontSize: '9px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
          }}
          direction="column"
        >
          {/* Lagoon data disclaimer */}
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: isMNR
                ? renderMnrDisclaimer()
                : printViewContent?.message.replace(/\n/g, '<br/>'),
            }}
          />
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: isMNROrCNSMember ? '' : renderVisionDisclaimer(),
            }}
            style={{ marginTop: '10px' }}
          />
        </Flex>
      </Flex>
    </Print.Section>
  );
};
