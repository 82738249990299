import { RouterProvider } from '@abyss/web/ui/RouterProvider';

import { Configuration } from '../ProviderSearch/context/Configuration';
import { createRouter } from '../ProviderSearchForPortal/createRouter';
import { GuestRoutes } from './GuestRoutes';

const router = createRouter(GuestRoutes, '/guest');

export const GuestExperience = () => {
  return (
    <Configuration>
      <RouterProvider router={router} />
    </Configuration>
  );
};
