import { Button } from '@abyss/web/ui/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconZoomIn, IconZoomOut } from './IconZoom';
import { LocateMe } from './LocateMe';
import { MapControlContainer, ZoomButton } from './MapDisplay.styled';

type Props = {
  map: any;
  showZoomButtons: boolean;
};
export const MapControlDesktop = ({ map, showZoomButtons }: Props) => {
  const { t } = useTranslation();
  const hidePopUp = () => {
    const popup = document.getElementsByClassName('mapboxgl-popup');
    if (popup?.length) {
      for (let i = 0; i < popup.length; i++) {
        popup[i].remove();
      }
    }
  };
  return (
    <React.Fragment>
      <MapControlContainer
        alignItems="center"
        css={{ right: '$md' }}
        direction="column"
      >
        {!showZoomButtons && (
          <ZoomButton
            alignItems="center"
            direction="column"
            justify="space-between"
          >
            <Button
              aria-label={t('LOCATION_FOCUS_BOX.ZOOM_IN')}
              data-auto-testid="zoom-in"
              data-testid="zoom-in"
              onClick={() => {
                hidePopUp();
                map?.zoomIn();
              }}
              rounded
              size="$sm"
              variant="ghost"
            >
              <IconZoomIn />
            </Button>
            <Button
              aria-label={t('LOCATION_FOCUS_BOX.ZOOM_OUT')}
              data-auto-testid="zoom-out"
              data-testid="zoom-out"
              onClick={() => {
                hidePopUp();
                map?.zoomOut();
              }}
              rounded
              size="$sm"
              variant="ghost"
            >
              <IconZoomOut />
            </Button>
          </ZoomButton>
        )}
        <LocateMe map={map} />
      </MapControlContainer>
    </React.Fragment>
  );
};
